import * as PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { Chakra } from '~/components/Chakra';
import { AccountProvider } from '~/lib/state/contexts/AccountContext/AccountContext';
import AlertProvider from '~/lib/state/contexts/AlertContext';
import { AwsWafProvider } from '~/lib/state/contexts/AwsWafContext';
import FinanceProvider from '~/lib/state/contexts/FinanceContext';
import { UkFinanceProvider } from '~/lib/state/contexts/UkFinanceContext';

export const Provider = ({ children }) => (
  <AwsWafProvider>
    <HelmetProvider>
      <Chakra>
        <AlertProvider>
          <AccountProvider>
            <FinanceProvider>
              <UkFinanceProvider>{children}</UkFinanceProvider>
            </FinanceProvider>
          </AccountProvider>
        </AlertProvider>
      </Chakra>
    </HelmetProvider>
  </AwsWafProvider>
);

Provider.propTypes = {
  children: PropTypes.node,
};
