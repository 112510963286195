import { Box, Divider, Heading, Text } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useConfig } from '~/hooks/useConfig';
import { useTranslationHelpers } from '~/hooks/useTranslationHelpers';
import { useTranslations } from '~/hooks/useTranslations';
import imgUrl, { getBrandPath } from '~/lib/helpers';
import { logEvent } from '~/lib/logEvent';
import { BuyPrompt } from '~/pages/brands/buy-prompt';

export function Brand({ brand }) {
  const [isHovering, setIsHovering] = useState(false);
  const { brandsTranslation } = useTranslations();
  const { offerAmountText } = useTranslationHelpers();
  const {
    publisher: { region },
  } = useConfig();

  const offer = brand?.offers?.[0];
  const maxCustomerOffer = brand?.max_customer_offer;
  const offerText = (() => {
    if (typeof maxCustomerOffer === 'number') {
      const showGetUpToText = offer.commission_groups?.length > 0;
      const offerPrefixText = showGetUpToText
        ? `${brandsTranslation.preOfferText.hasCommissionGroups} `
        : '';
      return `${offerPrefixText}${offerAmountText({
        offerAmount: maxCustomerOffer,
        region,
        rewardType: brand?.reward_type,
      })}`;
    }
  })();

  if (!brand?.brand_uid) return null;

  return (
    <Link
      onClick={() => {
        logEvent('navigation', {
          action: 'brand_link',
          label: brand.name,
          value: brand.brand_uid,
        });
      }}
      to={getBrandPath(brand)}
      style={{ width: '100%' }}
    >
      <Box
        key={brand.brand_uid}
        layerStyle='brand'
        data-role='brand'
        id={brand.brand_uid}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        sx={{
          border: '1px solid',
          borderColor: 'secondary.secondary',
          borderRadius: 'xl',
          overflow: 'hidden',
        }}
      >
        <Box
          layerStyle='brand-image'
          backgroundImage={imgUrl(brand.small_img_url || brand.large_img_url)}
        />
        <Divider />
        <Box
          p='12px 12px 20px 12px'
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          rowGap={4}
        >
          <Heading variant='brand-head'>{brand.name}</Heading>
          {maxCustomerOffer && (
            <Text variant='two-lines' fontSize='md' fontWeight={500} fontFamily='display'>
              {offerText}
            </Text>
          )}
        </Box>
        {isHovering && <BuyPrompt />}
      </Box>
    </Link>
  );
}

Brand.propTypes = {
  brand: PropTypes.object,
};
