const NextArrowIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={37} height={36} fill='none' {...props}>
    <circle
      cx={18.5}
      cy={18}
      r={18}
      fill='#161616'
      fillOpacity={0.23}
      transform='rotate(-180 18.5 18)'
    />
    <path stroke='#fff' strokeLinecap='round' strokeWidth={2} d='m15.5 24 6-6-6-6' opacity={0.7} />
  </svg>
);
export default NextArrowIcon;
