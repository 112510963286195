import { Text } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

export const Countdown = ({ seconds, onComplete, ...props }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(seconds);

  useEffect(() => {
    if (remainingSeconds > 0) {
      const intervalId = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else if (remainingSeconds === 0) {
      onComplete();
    }
  }, [remainingSeconds, onComplete]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return <Text {...props}>{formatTime(remainingSeconds)}</Text>;
};

Countdown.propTypes = {
  seconds: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};
