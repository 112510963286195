import { useCallback } from 'react';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { MARKETING_PREFERENCE_URL } from '~/lib/constants';

export const useMarketingApi = () => {
  const fetchUrl = useFetchUrl();

  const getMarketingPreference = useCallback(async () => {
    try {
      const response = await fetchUrl(MARKETING_PREFERENCE_URL, {
        method: 'GET',
      });

      return await response.json();
    } catch (error) {
      console.error('Fetching marketing preference error: ', error);
      throw error;
    }
  }, [fetchUrl]);

  const postMarketingPreference = useCallback(
    async (body) => {
      try {
        const response = await fetchUrl(MARKETING_PREFERENCE_URL, {
          method: 'POST',
          body: JSON.stringify(body),
        });

        return response;
      } catch (error) {
        console.error('Set marketing preference error: ', error);
        throw error;
      }
    },
    [fetchUrl]
  );

  return { getMarketingPreference, postMarketingPreference };
};
