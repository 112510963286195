import { Box, Center, Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa6';

export function AddCreditCardButton({ onClick }) {
  return (
    <Box
      backgroundColor='white'
      border='1px solid'
      borderColor={'blackAlpha.300'}
      cursor='pointer'
      flexShrink={0}
      height='112px'
      my={2}
      onClick={onClick}
      position='relative'
      width='168px'
    >
      <VStack width='100%' height='100%' justifyContent='center' alignItems='center' spacing={0}>
        <Box
          borderRadius='50%'
          bg='blackAlpha.400'
          width='24px'
          height='24px'
          mb='10px'
          color='white'
          p={1}
          textAlign='center'
        >
          <Center>
            <FaPlus />
          </Center>
        </Box>
        <Text fontSize='12px' color='blackAlpha.500'>
          Add Credit Card
        </Text>
      </VStack>
    </Box>
  );
}

AddCreditCardButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
