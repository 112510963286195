import { Badge, Box, Button, Circle, HStack, Text, useTheme, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa6';
import { InstitutionLogo } from '~/components/institution-logo';
import { useTranslations } from '~/hooks/useTranslations';
import { LINKED_CARDS_ERROR } from '~/lib/constants';

export const CreditCardItem = ({ inst, reAuth }) => {
  const theme = useTheme();
  const { financeTranslation: t } = useTranslations();

  return (
    <Box width='324px' height='110px' borderRadius='8px' shadow='md' p={4.5} m={6} flexShrink={0}>
      <HStack width='100%' height='100%' alignItems='center' gap={3}>
        <Box
          borderColor='secondary.tertiary'
          borderRadius='8px'
          borderStyle='solid'
          borderWidth='1px'
          width='67px'
          height='67px'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <InstitutionLogo institution={inst} size={9} color='secondary.primary' />
        </Box>
        <VStack ml={4} alignItems='start'>
          <Text fontSize='md' fontWeight='medium'>
            {inst.institution_name}
          </Text>
          {inst.error === LINKED_CARDS_ERROR.NEED_AUTHENTICATION && (
            <Badge colorScheme='red' variant='subtle' size='xl'>
              {t.disconnected}
            </Badge>
          )}
        </VStack>
        {inst.error === LINKED_CARDS_ERROR.NEED_AUTHENTICATION && (
          <Button ml='auto' variant='ghost' p={0} onClick={() => reAuth(inst.institution_id)}>
            <Circle bg='red.100' size={7}>
              <FaChevronRight color={theme.colors.red[700]} size={14} />
            </Circle>
          </Button>
        )}
      </HStack>
    </Box>
  );
};

CreditCardItem.propTypes = {
  inst: PropTypes.object,
  reAuth: PropTypes.func,
};
