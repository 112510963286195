const AlarmIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 33 32'
    width={33}
    height={32}
    fill='none'
    {...props}
  >
    <path
      fill='white'
      d='M17.491 10.738h-1.993v7.973L21.81 22.5l.997-1.635-5.316-3.15v-6.976Zm6.428-8.226 6.122 5.11-1.7 2.04-6.127-5.108 1.705-2.042Zm-14.185 0 1.704 2.04-6.124 5.11-1.7-2.042 6.12-5.108Zm7.093 2.91c-6.605 0-11.96 5.356-11.96 11.96 0 6.605 5.355 11.96 11.96 11.96 6.604 0 11.96-5.355 11.96-11.96 0-6.604-5.356-11.96-11.96-11.96Zm0 21.263c-5.13 0-9.303-4.173-9.303-9.303 0-5.13 4.173-9.302 9.303-9.302 5.13 0 9.302 4.173 9.302 9.302 0 5.13-4.173 9.303-9.302 9.303Z'
    />
  </svg>
);
export default AlarmIcon;
