import PropTypes from 'prop-types';
import { createContext, useMemo, useRef, useState } from 'react';
import { getAlertId } from '~/lib/helpers';

/**
 * @typedef {Object} Alert
 * @property {'error' | 'warning' | 'info' | 'success'} status
 * @property {string} description
 * @property {{icon: () => JSX.Element; action: () => void}} primaryAction
 * @property {{text: string; action: () => void}} secondaryAction
 * @property {string} location
 */

export const AlertStateContext = createContext();
export const AlertActionContext = createContext();

const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState(/** @type {Alert[]} */ ([]));
  const dismissedAlerts = useRef(new Set()); // dismissed alerts will remained dismissed until user refreshes page

  const alertActionValues = useMemo(
    () => ({
      addAlert: (alert) => {
        setAlerts((p) => {
          // if alert was dismissed this session, don't add it
          if (dismissedAlerts.current.has(getAlertId(alert))) {
            return p;
          }
          // skip duplicates
          if (p.some((a) => getAlertId(a) === getAlertId(alert))) {
            return p;
          }

          return [...p, alert];
        });
      },
      removeAlert: (alert) => {
        setAlerts((p) =>
          p.filter((a) => {
            if (getAlertId(a) === getAlertId(alert)) {
              dismissedAlerts.current.add(getAlertId(a));
              return false;
            } else {
              return true;
            }
          })
        );
      },
    }),
    []
  );

  const alertStateValues = useMemo(() => {
    return {
      alerts,
    };
  }, [alerts]);

  return (
    <AlertActionContext.Provider value={alertActionValues}>
      <AlertStateContext.Provider value={alertStateValues}>{children}</AlertStateContext.Provider>
    </AlertActionContext.Provider>
  );
};

AlertProvider.propTypes = { children: PropTypes.node.isRequired };

export default AlertProvider;
