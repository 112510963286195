import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa6';
import { useConfig } from '~/hooks/useConfig';
import { useTranslations } from '~/hooks/useTranslations';
import { SettingsGearsIcon } from '../../icons/settings-gears-icon';
import { PromotionalEmailToggle } from './promotional-email-toggle';
import { settingsModalView } from './settings-modal-container';

export const SettingsModalRoot = ({ setView }) => {
  const theme = useTheme();
  const { genericTranslation } = useTranslations();
  const config = useConfig();
  const showChangePassword = config?.account?.sso !== true;

  return (
    <>
      <ModalHeader display='flex' alignItems='center' flexDirection='column' pt={0}>
        <SettingsGearsIcon color={theme.colors.brand.primary} style={{ marginBottom: '12px' }} />
        <Text
          as='h1'
          fontWeight='medium'
          fontSize='xxl'
          fontFamily='display'
          color='neutral.primary'
        >
          {genericTranslation.settings.settings}
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody pt={0} display='flex' flexDirection='column' alignItems='flex-start' gap={4}>
        <VStack gap={2} alignItems='flex-start' width='100%'>
          {showChangePassword && (
            <>
              <Text
                color='secondary.primary'
                fontWeight='medium'
                fontFamily='display'
                fontSize='sm'
              >
                {genericTranslation.settings.account}
              </Text>
              <Button
                variant='outline'
                sx={{
                  width: '100%',
                  height: 16,
                  borderColor: 'secondary.tertiary',
                  fontWeight: 'medium',
                  '& svg, & p': {
                    color: 'neutral.primary',
                  },
                  _hover: {
                    ...(theme) => theme.components.Button.variants.outline._hover,
                    backgroundColor: 'brand.primary',
                    '& svg, & p': { color: 'surface.surface' },
                  },
                }}
                onClick={() => {
                  setView(settingsModalView.changePassword.form);
                }}
              >
                <HStack width='100%' justifyContent='space-between'>
                  <Text fontFamily='display' fontSize='18px'>
                    {genericTranslation.settings.changePassword.button}
                  </Text>
                  <FaChevronRight />
                </HStack>
              </Button>
            </>
          )}
        </VStack>
        <PromotionalEmailToggle />
      </ModalBody>
    </>
  );
};

SettingsModalRoot.propTypes = {
  setView: PropTypes.func.isRequired,
};
