import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import AlarmIcon from '~/components/icons/alarm-icon';
import CoinsIcon from '~/components/icons/coins-icon';
import { useConfig } from '~/hooks/useConfig';
import useIsNarrowScreen from '~/hooks/useIsNarrowScreen';
import { useRewardsRedemption } from '~/hooks/useRewardsRedemption';
import { useTranslations } from '~/hooks/useTranslations';
import { MIN_WITHDRAWAL_AMOUNT } from '~/lib/constants';
import { formatCurrency, getRegion } from '~/lib/helpers';
import RedemptionForms from '~/pages/account/rewards/redemption-forms';
import { RewardsCard } from './rewards-card';

export function RewardsContainer({ lastRedemptionStatus }) {
  const narrow = useIsNarrowScreen();
  const { rewardsTranslation: t } = useTranslations();

  const {
    isLoading,
    isSubmitting,
    isProcessing,
    availableCashback,
    totalEarnedCashback,
    pendingCashback,
    onNext,
    values,
    errors,
    handleBlur,
    handleChange,
    isModalOpen,
    onOpenModal,
    onCloseModal,
    onGoToStep,
    stepNumber,
    showConfetti,
  } = useRewardsRedemption({ lastRedemptionStatus });

  const config = useConfig();
  const region = getRegion(config);

  if (isLoading) {
    return <Spinner />;
  }

  const withdrawRequirement = MIN_WITHDRAWAL_AMOUNT - availableCashback;

  return (
    <Card height={narrow ? undefined : '100%'}>
      <CardHeader>{t.header}</CardHeader>
      <CardBody>
        <Stack
          data-role='stack'
          direction={narrow ? 'column' : 'row'}
          spacing={0}
          width={narrow ? '100%' : undefined}
          justify='center'
          alignItems={narrow ? 'stretch' : undefined}
          px={narrow ? undefined : '40px'}
          py={narrow ? '20px' : '10px'}
        >
          <VStack flex={1} p='20px' justify='center'>
            <Heading whiteSpace='nowrap' variant='card-header-no-line' fontWeight={600}>
              {t.cashbackTitle}
            </Heading>
            <Text
              py={0}
              my={0}
              lineHeight={1}
              fontWeight={600}
              fontSize='56px'
              fontFamily='display'
              color='secondary_color'
            >
              {formatCurrency(availableCashback, region)}
            </Text>
            <Button
              variant='baseStyle'
              isDisabled={withdrawRequirement > 0 || isProcessing}
              onClick={onOpenModal}
            >
              {t.withdrawBtn}
            </Button>
            {withdrawRequirement > 0 ? (
              <Text textStyle='minor'>
                {t.earnXMoreToWithdraw(formatCurrency(withdrawRequirement, region))}
              </Text>
            ) : null}
          </VStack>
          <HStack flexBasis={narrow ? '100%' : '300px'} justify='center'>
            <RewardsCard
              amount={formatCurrency(pendingCashback, region)}
              icon={<AlarmIcon width={50} height={50} />}
            >
              {t.pendingText}
            </RewardsCard>
            <RewardsCard
              amount={formatCurrency(totalEarnedCashback, region)}
              icon={<CoinsIcon width={50} height={50} />}
            >
              {t.earnedText}
            </RewardsCard>
          </HStack>
        </Stack>
      </CardBody>
      <RedemptionForms
        onNext={onNext}
        stepNumber={stepNumber}
        values={values}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        onGoToStep={onGoToStep}
        isModalOpen={isModalOpen}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        isSubmitting={isSubmitting}
        showConfetti={showConfetti}
      />
    </Card>
  );
}

RewardsContainer.propTypes = {
  lastRedemptionStatus: PropTypes.string,
};
