import { useEffect, useState } from 'react';
import { useConfig } from '~/hooks/useConfig.js';
import { FLAGS, useFeatureFlag } from '~/hooks/useFeatureFlag';
import { sha256Hash } from '~/lib/sha256Hash.js';

const configId = 'G-54VQHMR3SF';
const scriptEndpoint = `https://www.googletagmanager.com/gtag/js?id=${configId}`;

export const useGoogleAnalytics = () => {
  const { enabled: GoogleAnalyticsIsLive } = useFeatureFlag(FLAGS.GOOGLE_ANALYTICS_IS_LIVE);
  const publisherConfig = useConfig();
  const [hashedEmail, setHashedEmail] = useState(null);

  useEffect(() => {
    const email = window.localStorage?.getItem('userEmail');
    if (email !== null) {
      sha256Hash(email).then((hash) => setHashedEmail(hash));
    } else {
      setHashedEmail(null);
    }
  }, []);

  useEffect(() => {
    if (!GoogleAnalyticsIsLive) {
      return;
    }
    const script = document.createElement('script');
    script.id = 'googleTagManagerScript';
    script.src = scriptEndpoint;
    document.body.appendChild(script);

    const gtagScript = document.createElement('script');
    gtagScript.id = 'gtagScript';

    const commonData = {
      email: hashedEmail != null ? hashedEmail : 'logged_out',
      env: publisherConfig?.env,
      product: 'rewards',
      publisher_id: publisherConfig?.publisher?.publisher_id,
    };

    gtagScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${configId}', ${JSON.stringify(commonData)});
      window.gtag = gtag
      `;
    document.body.appendChild(gtagScript);

    return () => {
      document.getElementById('googleTagManagerScript').remove();
      document.getElementById('gtagScript').remove();
    };
  }, [
    GoogleAnalyticsIsLive,
    hashedEmail,
    publisherConfig?.env,
    publisherConfig?.publisher?.publisher_id,
  ]);
};
