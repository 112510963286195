import { useEffect, useRef, useState } from 'react';

/**
 *
 * @param {number} threshold The threshold to trigger the scroll direction
 * @returns {'up' | 'down'} The direction of the scroll
 */
export const useScrollDirection = (threshold = 0) => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // if we are at the top of the page, don't set a direction
      if (currentScrollY <= 0) {
        setScrollDirection(null);
        return;
      }

      const diff = currentScrollY - lastScrollY.current;

      // Check if the scroll has passed the threshold
      if (Math.abs(diff) > threshold) {
        if (diff > 0) {
          setScrollDirection('down');
        } else {
          setScrollDirection('up');
        }
        lastScrollY.current = currentScrollY;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [threshold]);

  return scrollDirection;
};
