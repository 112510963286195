import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useNavigate, useRouteError } from 'react-router-dom';
import { PageContainer } from '~/components/page-container';
import { useTranslations } from '~/hooks/useTranslations';

export function ErrorPage({ img, title, message, action, actionText }) {
  const error = useRouteError();
  const { genericTranslation } = useTranslations();
  const navigate = useNavigate();

  const renderErrorMessage = () => {
    if (typeof message === 'string') {
      return <Text>{message}</Text>;
    }
    if (Array.isArray(message)) {
      return message.map((msg) => <Text key={msg}>{msg}</Text>);
    }
    // If no message is provided, show a generic message along with status or message from the error object, this was the default behavior before
    if (!message) {
      return (
        <>
          <Text>{genericTranslation.errorPage.general.message}</Text>
          <Text as='em'>{error.statusText || error.message}</Text>
        </>
      );
    }
  };

  return (
    <PageContainer
      color='neutral.primary'
      p={16}
      justifyContent='center'
      fontFamily='poppins'
      spacing={8}
    >
      {img && (
        <Image src={img} display='block' maxWidth={80} maxheight={80} objectFit='contain' mb={4} />
      )}
      <Heading
        as='h1'
        sx={{
          fontSize: '2.75rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        {title ?? genericTranslation.errorPage.general.title}
      </Heading>
      <Box
        color='secondary.300'
        maxWidth='36rem'
        fontSize='lg'
        fontWeight='medium'
        textAlign='center'
      >
        {renderErrorMessage()}
      </Box>

      <Button
        onClick={() => {
          if (action) {
            action();
          } else {
            navigate('/');
          }
        }}
      >
        {actionText ?? genericTranslation.errorPage.general.actionText}
      </Button>
    </PageContainer>
  );
}

ErrorPage.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  action: PropTypes.func,
  actionText: PropTypes.string,
};
