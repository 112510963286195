import { Box, Center, Grid, GridItem, Image } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useBgGradient } from '~/hooks/useBgGradient';
import { useTranslations } from '~/hooks/useTranslations';
import { HorizontalBrandRow, IntroText, OfferButton } from '~/pages/landing/helpers';

export function LandingPage01Intro({ imgBrands, responsive }) {
  const { landingTranslation } = useTranslations();
  const bgGradient = useBgGradient();

  return (
    <Grid
      bgGradient={bgGradient}
      templateColumns={responsive.cols}
      templateRows={responsive.rows}
      templateAreas={responsive.areas}
      width='100%'
    >
      <GridItem area='text' data-role='grid-item-text'>
        <IntroText landingTranslation={landingTranslation} responsive={responsive} />
      </GridItem>
      <GridItem area='images' data-role='grid-item-images' display='flex' justifyContent='center'>
        <Box maxHeight={['220px', '550px']} maxWidth={['226px', '566px']}>
          <Image src='/img/lp/landing_image@2x.png' layerStyle='landing-image' />
        </Box>
      </GridItem>
      <GridItem area='footer' data-role='grid-item-footer'>
        {responsive.showOfferButton ? (
          <Box layerStyle='landing-brand-row-grid'>
            <Center>
              <OfferButton responsive={responsive} />
            </Center>
          </Box>
        ) : (
          <HorizontalBrandRow responsive={responsive} imgBrands={imgBrands} />
        )}
      </GridItem>
    </Grid>
  );
}

LandingPage01Intro.propTypes = {
  imgBrands: PropTypes.array,
  responsive: PropTypes.object,
};
