import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { CardsListCompact } from '~/components/cards-list-compact/CardsListCompact';
import { WhatNowIcon } from '~/components/icons/what-now-icon';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useTranslations } from '~/hooks/useTranslations';
import { FOOD_AND_DRINK_CATEGORY_ID, OFFER_LOCATION, OFFER_TYPE } from '~/lib/constants';
import { OfferTextBlock } from './offer-text-block';
import { ParticipatingLocations } from './ParticipatingLocations';

export const OfferInstructions = ({ offer, hasLinkedCards, categoryId }) => {
  const { brandsTranslation } = useTranslations();
  const { isLoggedIn } = useAccountState();
  const unknownOfferType = Object.values(OFFER_TYPE).includes(offer.offer_type) === false;
  const unknownOfferLocation = Object.values(OFFER_LOCATION).includes(offer.location) === false;

  if (unknownOfferType || unknownOfferLocation) {
    return (
      <OfferTextBlock
        headingText={brandsTranslation.details.location}
        headingIcon={(iconSize) => <WhatNowIcon boxSize={iconSize} />}
      >
        <Text>No details available</Text>
      </OfferTextBlock>
    );
  }

  const locationString = brandsTranslation.location[offer.location] ?? '';
  const isGiftCardOffer = offer.offer_type === OFFER_TYPE.GIFT_CARD;
  const isFoodAndDrink = categoryId === FOOD_AND_DRINK_CATEGORY_ID;
  const offerIntro = isGiftCardOffer ? (
    <Text>
      {`This gift card can ${
        offer.location !== OFFER_LOCATION.IN_STORE_ONLINE ? 'only ' : ''
      }be used at `}
      <Text as='strong'>{locationString}</Text> locations.
    </Text>
  ) : (
    <Text>
      This is an
      <Text as='strong'>{` ${locationString}${
        offer.location === OFFER_LOCATION.IN_STORE_ONLINE ? '' : ' Only'
      } `}</Text>
      offer.
    </Text>
  );
  const instructionsText = (() => {
    const offerTypeTranslation = brandsTranslation.offerType[offer.offer_type];
    const offerInstructions = offerTypeTranslation.instructions[offer.location];
    let instructions;

    if (isLoggedIn && hasLinkedCards) {
      instructions = offerInstructions.authenticated;
    } else if (isLoggedIn) {
      instructions = offerInstructions.noCardsLinked;
    } else {
      instructions = offerInstructions.unauthenticated;
    }

    return instructions.map((instruction) => {
      return <Text key={instruction}>{instruction}</Text>;
    });
  })();

  const inStoreOnlyDisclaimer = (
    <Text>{brandsTranslation.offerType.CARD_LINKED.instructions.IN_STORE_ONLY.disclaimer}</Text>
  );

  const isInStoreOnly = offer.location === OFFER_LOCATION.IN_STORE_ONLY;
  const offerAvailableInStore = isInStoreOnly || offer.location === OFFER_LOCATION.IN_STORE_ONLINE;

  const displayParticipatingLocations = offerAvailableInStore && offer.store_addresses?.length > 0;

  return (
    <OfferTextBlock
      headingText={brandsTranslation.details.location}
      headingIcon={(iconSize) => <WhatNowIcon boxSize={iconSize} />}
    >
      {offerIntro}
      {instructionsText}
      {isLoggedIn && isFoodAndDrink && <CardsListCompact />}
      {isLoggedIn && isInStoreOnly && inStoreOnlyDisclaimer}
      {displayParticipatingLocations && <ParticipatingLocations offer={offer} />}
    </OfferTextBlock>
  );
};

OfferInstructions.propTypes = {
  offer: PropTypes.shape({
    offer_type: PropTypes.string,
    location: PropTypes.oneOf(Object.values(OFFER_LOCATION)),
    store_addresses: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  hasLinkedCards: PropTypes.bool.isRequired,
  categoryId: PropTypes.number,
};
