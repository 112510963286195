import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';

const CustomModal = ({ children, footer, header, isOpen, onClose, preHeaderContent, ...props }) => {
  return (
    <Modal data-role='modal-container' isOpen={isOpen} onClose={onClose} isCentered {...props}>
      <ModalOverlay />
      <ModalContent data-role='modal-content'>
        {preHeaderContent}
        <ModalHeader data-role='modal-title'>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody data-role='modal-body'>{children}</ModalBody>
        <ModalFooter>{footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

CustomModal.displayName = 'CustomModal';

export default CustomModal;

CustomModal.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  header: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  preHeaderContent: PropTypes.node,
  props: PropTypes.object,
};
