import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

export const switchTheme = defineMultiStyleConfig({
  // The parts of the component
  parts: ['container', 'track', 'thumb'],
  // The base styles for each part
  baseStyle: {
    container: {},
    track: {
      padding: 1,
      width: 9,
    },
    thumb: {
      _checked: {
        transform: 'translateX(19px)',
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    colorScheme: 'secondary_scheme',
  },
});
