import { Box } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import imgUrl, { getBrandPath } from '~/lib/helpers';

export default function BrandToken({ brand }) {
  if (!brand) return null;
  return (
    <Link to={getBrandPath(brand)}>
      <Box
        width='140px'
        height='140px'
        backgroundImage={imgUrl(brand.logo_img_url)}
        backgroundRepeat='no-repeat'
        backgroundSize='cover'
        shadow='xl'
        borderRadius='20px'
      />
    </Link>
  );
}

BrandToken.propTypes = {
  brand: PropTypes.object,
};
