import { Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { LINKED_CARDS_ERROR } from '~/lib/constants';
import { CreditCardDetails } from './credit-card-details';

function CreditCardGroup({ institution }) {
  const { linked_cards: cards, error } = institution;

  return (
    <VStack>
      <Text color={'neutral.primary'} fontSize={'medium'}>
        {institution.institution_name}
      </Text>
      {error && error === LINKED_CARDS_ERROR.NEED_AUTHENTICATION && (
        <CreditCardDetails
          card={{ institution_id: institution.institution_id }}
          institution={institution}
        />
      )}
      {cards?.map((card) => (
        <CreditCardDetails key={card.account_id} card={card} institution={institution} />
      ))}
    </VStack>
  );
}

CreditCardGroup.propTypes = {
  institution: PropTypes.object,
};

export default CreditCardGroup;
