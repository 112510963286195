import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import {
  BRANDS_API_URL,
  CATEGORIES_API_URL,
  QUERY_PARAMS,
  SORT_BY,
  SORT_DIRECTION,
} from '~/lib/constants';
import { CustomError, handleError } from '~/lib/errors';

/**
 * @typedef {Object} Category
 * @property {string} category_id
 * @property {string} category
 */

/**
 * @typedef {Object} Offer
 * @property {string} offer_uid
 * @property {string} name
 * @property {number} customer_offer
 * @property {string} description
 * @property {Date} end_date
 * @property {string} location
 * @property {boolean} new_customer_only
 * @property {string} offer_type
 * @property {boolean} one_time_use
 * @property {number} refund_period_days
 * @property {string} reward_type
 * @property {Date} start_date
 * @property {string} terms_and_conditions
 * @property {string} tracking_url
 * @property {string} voucher_code
 * @property {string} small_img_url
 * @property {string} website
 */

/**
 * @typedef {Object} Brand
 * @property {string} brand_uid
 * @property {string} category
 * @property {number} category_id
 * @property {string} description
 * @property {string} large_img_url
 * @property {string} logo_img_url
 * @property {number} max_customer_offer
 * @property {string} name
 * @property {string} offer_description
 * @property {Offer[]} offers
 * @property {Object[]} commission_groups
 */

export const SearchContext = createContext();

const INITIAL_PAGE = 0;

const SearchProvider = ({ children }) => {
  const fetchUrl = useFetchUrl();
  const [searchParams] = useSearchParams();
  const [categories, setCategories] = useState(/** @type {Category[]} */ ([]));
  const [brands, setBrands] = useState(/** @type {Brand[]} */ ([]));
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [isBrandsLoading, setBrandsLoading] = useState(false);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [showMore, setShowMore] = useState(true);

  const categoryId = Number(searchParams.get(QUERY_PARAMS.CATEGORY_ID)) || undefined;

  const activeCategory = useMemo(
    () => categories.find((c) => c.category_id === categoryId),
    [categoryId, categories]
  );
  const searchPhrase = searchParams.get(QUERY_PARAMS.SEARCH_TEXT) || '';

  useEffect(() => {
    const loadCategories = async () => {
      setIsCategoriesLoading(true);

      try {
        const res = await fetchUrl(CATEGORIES_API_URL);
        const data = await res.json();
        if (Array.isArray(data)) {
          setCategories(data);
        } else {
          throw new CustomError({
            title: 'Could not fetch brand categories',
            description: 'Unexpected response format',
          });
        }
      } catch (error) {
        handleError(error, 'Card NOT linked');
      } finally {
        setIsCategoriesLoading(false);
      }
    };

    loadCategories();
  }, [fetchUrl]);

  useEffect(() => {
    setPage(INITIAL_PAGE);
    setBrands([]);
  }, [searchPhrase]);

  useEffect(() => {
    const fetchBrands = async () => {
      setBrandsLoading(true);

      const params = new URLSearchParams(searchParams);
      params.set(QUERY_PARAMS.SIZE, 100);
      params.set(QUERY_PARAMS.PAGE, page);
      params.set(QUERY_PARAMS.SORT, `${SORT_BY.OFFER_RANK},${SORT_DIRECTION.ASC}`);

      try {
        const response = await fetchUrl(`${BRANDS_API_URL}?${params.toString()}`);

        if (!response.ok) {
          throw new Error('Bad response');
        }

        const { content, number, totalPages } = await response.json();

        if (Array.isArray(content)) {
          setBrands((prevBrands) => (page === 0 ? content : [...prevBrands, ...content]));
          setShowMore(totalPages > number + 1);
        } else {
          throw new CustomError({
            title: 'Could not fetch brand categories',
            description: 'Unexpected response format',
          });
        }
      } catch (error) {
        handleError(error, 'Card NOT linked');
      } finally {
        setBrandsLoading(false);
        setIsFetchingMore(false);
      }
    };

    fetchBrands();
  }, [searchParams, page, categoryId, fetchUrl]);

  useEffect(() => {
    setPage(INITIAL_PAGE);
    setBrands([]);
  }, [categoryId, searchPhrase]);

  const handleMore = useCallback(() => {
    if (isFetchingMore || !showMore) return;

    setIsFetchingMore(true);
    setPage((prevPage) => prevPage + 1);
  }, [isFetchingMore, showMore, setPage]);

  const value = useMemo(
    () => ({
      brands,
      activeCategory,
      categories,
      isCategoriesLoading,
      isBrandsLoading,
      onMore: handleMore,
      showMore,
      searchPhrase,
    }),
    [
      brands,
      activeCategory,
      categories,
      isCategoriesLoading,
      isBrandsLoading,
      handleMore,
      showMore,
      searchPhrase,
    ]
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

SearchProvider.propTypes = { children: PropTypes.node.isRequired };

export default SearchProvider;
