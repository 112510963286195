import { Heading, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useConfig } from '~/hooks/useConfig';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { BRANDS_API_URL } from '~/lib/constants';
import { responseStatusIsGood } from '~/lib/helpers';
import { StoreWeLove } from './store-we-love';

export const StoresWeLoveSection = () => {
  const fetchUrl = useFetchUrl();
  const [brands, setBrands] = useState([]);
  const {
    publisher: { stores_we_love: storesWeLoveConfig },
  } = useConfig();
  const storeColumns = useBreakpointValue(
    {
      base: 1,
      sm: 2,
      md: 3,
      lg: 6,
      xl: 6,
    },
    {
      fallback: 'md',
    }
  );
  const visibleStores = brands.filter((brand) => brand?.brand_uid != null).slice(0, storeColumns);

  useEffect(() => {
    const fetchStoresWeLove = async () => {
      const newBrands = await Promise.all(
        storeIds.map(async (id, index) => {
          try {
            const rank = storeIds.length - index;

            const response = await fetchUrl(`${BRANDS_API_URL}/${id}`);

            if (!responseStatusIsGood(response)) {
              throw new Error();
            }

            const store = await response.json();

            store.rank = rank || 0;
            return store;
          } catch (err) {
            console.debug('cannot fetch store id', id, err);
          }
        })
      );

      setBrands(newBrands);
    };

    const storeIds = storesWeLoveConfig.map((storeWeLove) => storeWeLove.brand_uid);

    if (storeIds?.length) {
      fetchStoresWeLove();
    }
  }, [storesWeLoveConfig, fetchUrl]);

  if (visibleStores.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Heading variant='homepage-head'>Cash Back at Stores We Love</Heading>
      <SimpleGrid columns={visibleStores.length} w='100%' p={0} m={0}>
        {visibleStores.map((brand) => (
          <StoreWeLove key={brand.brand_uid} brand={brand} />
        ))}
      </SimpleGrid>
    </React.Fragment>
  );
};
