import { Box, Center, Image } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import imgUrl from '~/lib/helpers';
import { GIFT_CARD_STORE_LOGO_SIZE_PX } from '~/theme/constants';

export const GIFT_CARD_IMG_WIDTH = 305;

export function GiftCardIcon({ brand, offer }) {
  return (
    <Box
      w={`${GIFT_CARD_IMG_WIDTH}px`}
      h='196px'
      mb={4}
      backgroundColor='rgb(100,100,100)'
      borderRadius='0.75rem'
      backgroundSize='auto'
      position='relative'
      {...(offer.banner_url && {
        backgroundImage: imgUrl(offer.banner_url),
      })}
    >
      {!offer.banner_url ? (
        <>
          <Image src='/img/gift-card-box.svg' position='absolute' bottom={0} right={0} w={160} />
          <Center
            position='absolute'
            left='25%'
            top='50%'
            transform='translateX(-50%) translateY(-50%)'
          >
            <Box
              backgroundColor='surface.white'
              w={GIFT_CARD_STORE_LOGO_SIZE_PX}
              h={GIFT_CARD_STORE_LOGO_SIZE_PX}
              borderWidth='2px'
              borderStyle='solid'
              borderRadius='50%'
              borderColor='secondary.secondary'
              m={4}
              overflow='hidden'
            >
              <Image src={brand.logo_img_url} />
            </Box>
          </Center>
        </>
      ) : null}
    </Box>
  );
}

GiftCardIcon.propTypes = {
  brand: PropTypes.object,
  offer: PropTypes.object,
};
