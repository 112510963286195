import { Box, Button, Text, useBreakpointValue } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Carousel from '~/components/carousel/carousel';
import { NextArrowIcon, PrevArrowIcon } from '~/components/icons';
import { BRANDS_SEARCH_ROUTE, QUERY_PARAMS } from '~/lib/constants';
import imgUrl from '~/lib/helpers';

const settings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 2000,
  autoplaySpeed: 4000,
};

export default function CategorySlider({ categories }) {
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const responsive = useBreakpointValue(
    {
      base: 'none',
      md: 'block',
    },
    { fallback: 'md', ssr: false }
  );

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToCategory = (categoryId) => {
    const search = new URLSearchParams();
    search.set(QUERY_PARAMS.CATEGORY_ID, categoryId);
    navigate(`${BRANDS_SEARCH_ROUTE}?${search.toString()}`);
  };

  const categoryGrid = categories.map((currentCategory) => (
    <Box data-role='carousel' key={currentCategory.key} layerStyle='carousel'>
      <Box
        data-role='carousel-background'
        layerStyle='carousel-background'
        backgroundImage={imgUrl(currentCategory?.image_path)}
        mx={2}
        width='calc(100% - 8px)'
      >
        <Box
          position='absolute'
          borderRadius='24px'
          top='0'
          left='0'
          width='100%'
          height='100%'
          bg='rgba(0, 0, 0, 0.4)'
        />
        <Box data-role='carousel-details' layerStyle='category-details' position='relative'>
          <Text variant='carousel-head' textStyle='carousel-title'>
            {currentCategory?.title}
          </Text>
          <Text variant='two-lines' textStyle='carousel-desc'>
            {currentCategory?.description}
          </Text>

          <Button
            variant='baseStyle'
            onClick={() => goToCategory(currentCategory?.category_id)}
            layerStyle='btn-carousel'
          >
            Cashback on {currentCategory?.key}
          </Button>
        </Box>
        {responsive === 'block' && (
          <>
            <Box cursor='pointer' onClick={previous} layerStyle='carousel-prev-arrow'>
              <PrevArrowIcon />
            </Box>
            <Box cursor='pointer' onClick={next} layerStyle='carousel-next-arrow'>
              <NextArrowIcon />
            </Box>
          </>
        )}
      </Box>
    </Box>
  ));

  return (
    <Carousel sliderRef={sliderRef} displayArrows {...settings}>
      {categoryGrid}
    </Carousel>
  );
}

CategorySlider.propTypes = {
  categories: PropTypes.array,
};
