export const rewardsTranslation = {
  header: 'Rewards',
  cashbackTitle: 'Available Cash Back',
  withdrawBtn: 'Withdraw Cash',
  pendingText: 'Pending Cash',
  earnedText: 'Lifetime Earned',
  edit: 'Edit',

  earnXMoreToWithdraw: (amount) => `Earn another ${amount} to withdraw`,

  enterBankInfo: 'Please enter your bank account information',
  selectAccountType: 'Select Account Type',
  savingsAccount: 'Saving Account',
  checkingAccount: 'Checking Account',
  accountHolderName: 'Account Holder Name',
  accountHolderNameInfo: 'Your name as it appears on your bank account',
  routingNumber: 'Routing Number',
  accountNumber: 'Account Number',
  reAccountNumber: 'Re-enter Account Number',
  daysToReceive: 'It may take up to 3 -5 business days to receive your funds.',
  authConfirmation:
    'Check the box to confirm you are the account holder and authorize the ACH credit.',

  howMuchWithdraw: 'How much would you like to withdraw?',
  whereTransfer: 'Where would you like to transfer your funds?',
  withdrawalProcessing: 'Your withdrawal is being processed',
  withdrawalEmail: 'You will receive an email confirmation once the funds have been transferred.',
  confirmTransfer: 'Please confirm your transfer',

  validation: {
    required: 'Required',
    maxWithdrawalAmount: (num) => `Maximum withdrawal amount is $${num}`,
    minWithdrawalAmount: (num) => `Minimum withdrawal amount is $${num}`,
    redemptionAmount:
      'Redemption amount cannot be greater than available cashback or greater than $500',
    accountNumber: 'Account number is required',
    accountNumberLength: 'Account number must be 9 digits',
    reenterAccountNumber: 'Re-enter account number is required',
    accountNumberMatch: 'Account number does not match',
    routingNumber: 'Routing number is required',
    routingNumberLength: 'Routing number must be 9 digits',
    accountHolderName: 'Account holder name is required',
    accountType: 'Account type is required',
    terms: 'You must confirm authorization to proceed',
  },
};
