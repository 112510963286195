import { useContext } from 'react';
import { AlertActionContext } from '~/lib/state/contexts/AlertContext';

export const useAlertAction = () => {
  const alertActionValues = useContext(AlertActionContext);
  if (!alertActionValues) {
    throw new Error('useAlertAction must be used within AlertProvider');
  }
  return alertActionValues;
};
