import { Card, CardBody } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import AccountTabs from '~/pages/account/account-tabs';

export function TransactionsSummaryPanel({ narrow, ...props }) {
  return (
    <Card height={narrow ? undefined : '100%'} mb='40px'>
      <CardBody>
        <AccountTabs narrow={narrow} {...props} />
      </CardBody>
    </Card>
  );
}

TransactionsSummaryPanel.propTypes = {
  narrow: PropTypes.bool,
  withdrawals: PropTypes.arrayOf(PropTypes.object),
  transactions: PropTypes.arrayOf(PropTypes.object),
  searchFieldPhrase: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  showMore: PropTypes.bool,
  onShowMore: PropTypes.func,
  onSearchFieldChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onResetDate: PropTypes.func,
  onReset: PropTypes.func,
};
