import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const WhatNowIcon = ({ boxSize = 4, color = 'currentColor', ...props }) => {
  return (
    <Icon fill='none' boxSize={boxSize} viewBox='0 0 30 30' color={color} {...props}>
      <circle cx='15' cy='15' r='14.2' stroke='currentColor' strokeWidth='1.6' />
      <path
        d='M16.5713 10.2853L20.0088 14.9728L16.5713 19.6603'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.1426 10.2853L14.5801 14.9728L11.1426 19.6603'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};

WhatNowIcon.propTypes = {
  boxSize: PropTypes.number,
  color: PropTypes.string,
};
