import { useEffect } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import { LINKED_CARDS_ERROR } from '~/lib/constants';
import { currentTimeInMS } from '~/lib/helpers';
import { TOAST_STATUS } from '~/lib/message';
import { useAccountState } from './account/useAccountState';
import { useAlertAction } from './alerts/useAlertAction';
import { useFinance } from './useFinance';

export const useCardNeedsReauthAlert = () => {
  const { isLoggedIn, accessTokenExpiration } = useAccountState();
  const isExpired = !accessTokenExpiration || accessTokenExpiration < currentTimeInMS();
  const isAuthenticated = isLoggedIn && !isExpired;
  const { institutions, reAuth } = useFinance();
  const { addAlert, removeAlert } = useAlertAction();

  // look for reAuth errors and add alerts if necessary
  useEffect(() => {
    if (isAuthenticated) {
      institutions.forEach((institution) => {
        if (institution.error === LINKED_CARDS_ERROR.NEED_AUTHENTICATION) {
          const alert = {
            status: TOAST_STATUS.ERROR,
            description: `ACTION REQUIRED: Re-connect to ${institution.institution_name} so you don't miss out on cash back.`,
            primaryAction: {
              icon: FaChevronRight,
              action: () => {
                reAuth(institution.institution_id, {
                  onSuccess: () => {
                    removeAlert(alert);
                  },
                });
              },
            },
            secondaryAction: {
              text: 'Set Up Later',
              action: () => {
                removeAlert(alert);
              },
            },
          };
          addAlert(alert);
        }
      });
    }
  }, [isAuthenticated, addAlert, institutions, reAuth, removeAlert]);
};
