import { useMemo } from 'react';
import { useConfig } from './useConfig';

export const useBgGradient = () => {
  const { theme } = useConfig();
  return useMemo(() => {
    const colorValues = Object.values(theme.colors.lp);

    return [
      `linear(to-t, ${colorValues.join(', ')})`,
      `linear(to-t, ${colorValues.join(', ')})`,
      `linear(to-bl, ${colorValues.join(', ')})`,
    ];
  }, [theme]);
};
