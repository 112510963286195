import { useCallback } from 'react';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { GOCARDLESS } from '~/lib/constants';

export const useUkInstitutionsApi = () => {
  const fetchUrl = useFetchUrl();

  const getGoCardlessInstitutions = useCallback(
    async (searchPhrase) => {
      try {
        const response = await fetchUrl(`${GOCARDLESS}/institutions?${searchPhrase}`);

        return await response.json();
      } catch (error) {
        console.error('Error fetching institutions:', error);
        throw error;
      }
    },
    [fetchUrl]
  );

  const getRedirectUrl = useCallback(
    async (institutionId) => {
      try {
        const response = await fetchUrl(
          `${GOCARDLESS}/redirect/${institutionId}?redirectUrl=${window.location.href}`
        );

        return await response.json();
      } catch (error) {
        console.error('Error fetching redirectUrl:', error);
        throw error;
      }
    },
    [fetchUrl]
  );

  const getPostLinkCallback = useCallback(
    async (gcRedirectParam) => {
      try {
        const response = await fetchUrl(`${GOCARDLESS}/link-successful-cb/${gcRedirectParam}`);

        return await response.json();
      } catch (error) {
        console.error('Error handling institution linking:', error);
        throw error;
      }
    },
    [fetchUrl]
  );

  const deleteCard = useCallback(
    async (institutionId) => {
      try {
        const response = await fetchUrl(`${GOCARDLESS}/${institutionId}`, {
          method: 'DELETE',
        });

        return await response.json();
      } catch (error) {
        console.error('Error unlinking institution:', error);
        throw error;
      }
    },
    [fetchUrl]
  );

  return { deleteCard, getGoCardlessInstitutions, getPostLinkCallback, getRedirectUrl };
};
