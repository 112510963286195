import { Box, Card, CardBody, Divider, VStack } from '@chakra-ui/react';
import { useAccountState } from '~/hooks/account/useAccountState';
import useIsNarrowScreen from '~/hooks/useIsNarrowScreen';
import { CardInfo } from '~/pages/account/card-info';
import { UserInfo } from '~/pages/account/user-info';
import { LinkedCardsInfo } from '~/pages/linked-cards-info';

export function UserAndCard() {
  const { isLoggedIn } = useAccountState();
  const narrow = useIsNarrowScreen();

  if (!isLoggedIn) return null;
  if (!narrow) {
    return (
      <Card minHeight='100%' mb='20px' maxWidth='320px'>
        <CardBody>
          <VStack alignItems='stretch' h='100%' w='100%'>
            <Box flexBasis='50%'>
              <CardInfo />
            </Box>
            <Box flexBasis='50%'>
              <LinkedCardsInfo />
            </Box>
          </VStack>
        </CardBody>
      </Card>
    );
  }
  return (
    <Card width='100%'>
      <CardBody data-role='card body'>
        <UserInfo />
        <Divider />
        <CardInfo />
      </CardBody>
    </Card>
  );
}
