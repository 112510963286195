const UploadSuccessIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={52} height={36} fill='none' {...props}>
    <g fill='#444' fillRule='evenodd' clipPath='url(#a)' clipRule='evenodd'>
      <path d='m1.27.21 50.029 17.784a.805.805 0 0 1 .537.84.812.812 0 0 1-.7.716l-15.08 2.096a.831.831 0 0 1-.525-.1L8.966 6.437l20.28 16.558a.799.799 0 0 1-.249 1.38l-16.595 5.808a.823.823 0 0 1-1.04-.47L.22 1.265a.795.795 0 0 1-.004-.011M9.362 4.8l38.28 13.607-11.536 1.604L9.362 4.8ZM2.873 3.558 12.605 28.4l14.486-5.07L2.873 3.558Z' />
      <path d='M36.286 20.117a.825.825 0 0 0-1.088.393l-5.275 11.199-.382-8.13a.814.814 0 0 0-.856-.769.811.811 0 0 0-.78.843l.535 11.42a.823.823 0 0 0 1.562.302l6.683-14.188a.8.8 0 0 0-.4-1.07Z' />
      <path d='M21.586 25.791a.797.797 0 0 0-.116 1.135l7.154 8.62a.829.829 0 0 0 1.153.114.797.797 0 0 0 .116-1.134l-7.154-8.62a.829.829 0 0 0-1.153-.114Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M52 0H0v36h52z' />
      </clipPath>
    </defs>
  </svg>
);
export default UploadSuccessIcon;
