import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const MapIcon = ({ size = 8, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 26 32' fill='none' color={color}>
      <svg
        width='26'
        height='26'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='map'>
          <path
            id='vector'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.7455 3.63387C15.7622 3.9456 14.2893 5.53604 14.1843 7.47924C14.1494 8.12415 14.274 8.625 14.687 9.50083C15.0611 10.2943 15.5591 11.0833 16.3724 12.1717C16.8836 12.8557 17.7374 13.8904 17.9118 14.0372C18.1153 14.2085 18.4384 14.2085 18.6419 14.0372C18.8163 13.8904 19.6701 12.8557 20.1813 12.1717C20.9947 11.0833 21.4927 10.2943 21.8668 9.50083C22.2797 8.625 22.4043 8.12415 22.3694 7.47924C22.2863 5.94024 21.3123 4.55454 19.8848 3.94431C19.3507 3.71603 19.0057 3.6434 18.3868 3.62895C18.0946 3.62213 17.806 3.62437 17.7455 3.63387ZM18.8488 6.06827C19.3476 6.23983 19.749 6.64381 19.9314 7.15772C20.0345 7.44836 20.0354 7.98291 19.9332 8.2707C19.7472 8.79447 19.3555 9.18759 18.8357 9.37228C18.5426 9.47637 18.0111 9.47637 17.7181 9.37228C17.1982 9.18759 16.8065 8.79447 16.6205 8.2707C16.5192 7.98544 16.5192 7.44822 16.6205 7.16296C16.9497 6.23624 17.9328 5.75328 18.8488 6.06827ZM6.6693 9.41818C3.83804 10.5559 3.75342 10.5956 3.65734 10.8285C3.62916 10.8969 3.62036 12.5431 3.62721 16.468C3.63685 22.0018 3.63696 22.0112 3.71241 22.1123C3.83258 22.2733 3.96406 22.353 4.14267 22.3732C4.29468 22.3903 4.52815 22.3041 6.89533 21.3574L9.48186 20.3228V14.3131C9.48186 11.0078 9.46948 8.30494 9.45438 8.30674C9.43928 8.30854 8.18599 8.80869 6.6693 9.41818ZM10.6545 14.3129V20.3224L12.9907 21.2574L15.3269 22.1923L15.3362 17.4768L15.3455 12.7613L15.1989 12.5581C14.4682 11.5454 13.8642 10.5223 13.4964 9.67407L13.3711 9.38511L12.0226 8.84429C11.281 8.54683 10.6698 8.30348 10.6644 8.30348C10.659 8.30348 10.6545 11.0077 10.6545 14.3129ZM22.2603 11.1996C21.6737 12.2438 19.7375 14.7112 19.2513 15.0342C18.9374 15.2428 18.676 15.3192 18.2769 15.3192C17.6779 15.3192 17.3171 15.1358 16.8158 14.5764L16.5179 14.2439V18.3174C16.5179 21.5614 16.5272 22.3872 16.5637 22.3728C16.5889 22.3628 17.8363 21.8635 19.3358 21.2631C22.1231 20.147 22.2472 20.0892 22.3404 19.8625C22.3708 19.7887 22.3794 18.5834 22.3724 15.3893L22.3629 11.0171L22.2603 11.1996Z'
            fill='#01B694'
          />
        </g>
      </svg>
    </Icon>
  );
};

MapIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default MapIcon;
