import * as PropTypes from 'prop-types';

export function ActivatedIcon({ mainColor = '#01B684', checkColor = 'white', ...props }) {
  return (
    <svg
      width='23'
      height='24'
      viewBox='0 0 23 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.2028 0.101638C11.3777 -0.0338791 11.6222 -0.0338792 11.7971 0.101637L14.5104 2.20385C14.5995 2.27288 14.7098 2.30873 14.8225 2.30526L18.2532 2.19937C18.4744 2.19255 18.6722 2.33623 18.734 2.54867L19.6935 5.84424C19.725 5.95245 19.7932 6.04632 19.8864 6.10973L22.7241 8.04061C22.9071 8.16509 22.9826 8.39757 22.9078 8.60579L21.7469 11.8359C21.7088 11.942 21.7088 12.058 21.7469 12.1641L22.9078 15.3942C22.9826 15.6024 22.9071 15.8349 22.7241 15.9594L19.8864 17.8903C19.7932 17.9537 19.725 18.0475 19.6935 18.1558L18.734 21.4513C18.6722 21.6638 18.4744 21.8075 18.2532 21.8006L14.8225 21.6947C14.7098 21.6913 14.5995 21.7271 14.5104 21.7962L11.7971 23.8984C11.6222 24.0339 11.3777 24.0339 11.2028 23.8984L8.4895 21.7962C8.40041 21.7271 8.29006 21.6913 8.1774 21.6947L4.74664 21.8006C4.52548 21.8075 4.32773 21.6638 4.26587 21.4513L3.30641 18.1558C3.27491 18.0475 3.20671 17.9537 3.11353 17.8903L0.275746 15.9594C0.0928115 15.8349 0.0172743 15.6024 0.0921081 15.3942L1.25297 12.1641C1.29109 12.058 1.29109 11.942 1.25297 11.8359L0.0921082 8.60579C0.0172744 8.39757 0.0928113 8.16509 0.275746 8.04061L3.11353 6.10973C3.20671 6.04632 3.27491 5.95245 3.30641 5.84424L4.26587 2.54867C4.32773 2.33623 4.52548 2.19255 4.74664 2.19937L8.1774 2.30526C8.29006 2.30873 8.40041 2.27288 8.4895 2.20385L11.2028 0.101638Z'
        fill={mainColor}
      />
      <path
        d='M15.5041 8.06683C15.5925 7.97846 15.7368 7.97762 15.8264 8.06495L16.674 8.89179C16.717 8.93373 16.7414 8.9908 16.7417 9.05046C16.7421 9.11012 16.7184 9.16748 16.6759 9.20991L9.94459 15.9332C9.90178 15.9759 9.8434 16 9.78249 16C9.72158 16 9.6632 15.9759 9.62039 15.9332L6.3241 12.6408C6.23563 12.5525 6.23648 12.41 6.32601 12.3227L7.17367 11.4959C7.21667 11.4539 7.27478 11.4306 7.33522 11.4309C7.39566 11.4313 7.45348 11.4553 7.49597 11.4978L9.78249 13.7815L15.5041 8.06683Z'
        fill={checkColor}
      />
    </svg>
  );
}

ActivatedIcon.propTypes = {
  checkColor: PropTypes.string,
  mainColor: PropTypes.string,
};
