import { CircularProgress, Divider, Stack, Text, VStack } from '@chakra-ui/react';
import { useFinance } from '~/hooks/useFinance';
import { useTranslations } from '~/hooks/useTranslations';
import CreditCardGroup from './account/cards/credit-card-group';

export function LinkedCardsInfo() {
  const { cards, institutions, isLoading } = useFinance();
  const { financeTranslation: t } = useTranslations();

  if (isLoading) {
    return (
      <Stack padding={6} alignItems={'center'} justifyContent='center'>
        <CircularProgress size={8} isIndeterminate color='brand.primary' />
      </Stack>
    );
  }

  if (!cards.length && !institutions.length) {
    return null;
  }

  return (
    <VStack width='100%' flexBasis='50%' data-role='user-Vstack' spacing={2} padding={6}>
      <Divider />
      <Text
        color='neutral.primary'
        fontSize='1rem'
        lineHeight='1'
        paddingY={2}
        fontWeight='500'
        alignSelf='start'
      >
        {t.linkedCards}
      </Text>
      <Divider />
      {institutions.map((inst) => (
        <CreditCardGroup key={inst.institution_id} institution={inst} />
      ))}
    </VStack>
  );
}
