import { Box, GridItem, Heading, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useTranslations } from '~/hooks/useTranslations';
import { RelatedOffer } from './related-offer';

export const RelatedOffersSection = ({ offers }) => {
  const { brandsTranslation } = useTranslations();

  return (
    <GridItem name='related-offers'>
      <Box>
        <Heading variant='related-offer'>{brandsTranslation.relatedOffers}</Heading>
        <VStack gap='12px' w='100%'>
          {offers.map((offer) => (
            <RelatedOffer key={offer.brand_uid} brand={offer} />
          ))}
        </VStack>
      </Box>
    </GridItem>
  );
};

RelatedOffersSection.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.object),
};
