import { Image } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { BankDefaultIcon } from '~/components/icons/bank-default-icon';

const IMG_PREFIX = 'data:img/png;base64,';

export function InstitutionLogo({ institution, size = 4, color = 'white' }) {
  if (!institution?.logo) {
    return <BankDefaultIcon boxSize={size} color={color} />;
  }
  return (
    <Image
      alt={institution?.institution_name + 'logo'}
      src={institution?.logo ? `${IMG_PREFIX}${institution.logo}` : '/icons/default-bank-icon.svg'}
      boxSize={size}
      objectFit='contain'
    />
  );
}

InstitutionLogo.propTypes = {
  color: PropTypes.string,
  institution: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
