import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

const search = definePartsStyle({
  addon: {
    borderY: '1px solid',
    borderLeft: '1px solid',
    borderColor: 'blackAlpha.400',
    borderRightColor: 'white',
  },
  field: {
    borderY: '1px solid',
    borderRight: '1px solid',
    borderColor: 'blackAlpha.400',
    borderLeftColor: 'white',
    backgroundColor: 'white',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
  },
});

const searchInst = definePartsStyle({
  addon: {
    borderY: '1px solid',
    borderLeft: '1px solid',
    borderColor: 'blackAlpha.400',
    borderRightColor: 'white',
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
  },
  field: {
    borderY: '1px solid',
    borderRight: '1px solid',
    borderColor: 'blackAlpha.400',
    borderLeftColor: 'white',
    backgroundColor: 'white',
    borderTopRightRadius: '12px',
    borderBottomRightRadius: '12px',
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle: {
    field: {
      border: '1px solid whiteAlpha.400',
    },
  },
  variants: { search, searchInst },
});
