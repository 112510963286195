import { useContext } from 'react';
import { CustomError } from '~/lib/errors';
import { UkFinanceContext } from '~/lib/state/contexts/UkFinanceContext';

export const useUkFinance = () => {
  const ctx = useContext(UkFinanceContext);

  if (!ctx) {
    throw new CustomError({
      title: 'A client-side error occurred',
      description: 'You have attempted to access UkFinanceContext from outside a UkFinanceProvider',
    });
  }

  return ctx;
};
