import { extendTheme } from '@chakra-ui/react';
import {
  alertTheme,
  brandTheme,
  buttonTheme,
  cardTheme,
  checkboxTheme,
  colors,
  formLabelTheme,
  headingTheme,
  inputTheme,
  layerStyles,
  listTheme,
  modalTheme,
  switchTheme,
  tabsTheme,
  textStyles,
} from '~/theme/default';

//@ TODO: (big) break out theme in to component based sub-themes
// -- NOT NOW as the site needs to evolve before the uniqueness of dependencies are clear.

export const defaultTheme = extendTheme(
  {
    breakpoints: {
      base: '0px',
      sm: '500px',
      md: '800px',
      lg: '1200px',
      xl: '1600px',
      '2xl': '1600px',
    },
    colors,
    components: {
      Alert: alertTheme,
      Tabs: tabsTheme,
      Card: cardTheme,
      List: listTheme,
      Text: {
        variants: {
          'brand-desc': {
            noOfLines: 1,
          },
          'two-lines': {
            noOfLines: 2,
          },
          'one-line': {
            noOfLines: 1,
          },
        },
      },
      Heading: headingTheme,
      Button: buttonTheme,
      Input: inputTheme,
      Modal: modalTheme,
      Switch: switchTheme,
      FormLabel: formLabelTheme,
      Checkbox: checkboxTheme,
    },
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
    fontSizes: {
      xs: '0.75rem',
      sm: '0.875rem',
      md: '1rem',
      lg: '1.25rem',
      xl: '1.5rem',
      '2xl': '2rem',
      '3xl': '2.5rem',
      '4xl': '3rem',
      '5xl': '3.5rem',
      '6xl': '4rem',
      '7xl': '4.5rem',
      '8xl': '5rem',
      '9xl': '6rem',
    },
    fonts: {
      display: '"Poppins", sans-serif',
      body: 'Roboto, sans-serif',
    },
    layerStyles,
    styles: {
      global: {
        body: {
          bg: 'white',
        },
      },
    },
    textStyles,
  },
  brandTheme
);
