import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const PlusCircleIcon = ({ boxSize = 4, color = 'currentColor', ...props }) => {
  return (
    <Icon boxSize={boxSize} viewBox='0 0 16 17' fill='none' color={color} {...props}>
      <path
        d='M8.01562 2C5.67188 2 3.54688 3.25 2.35938 5.25C1.20312 7.28125 1.20312 9.75 2.35938 11.75C3.54688 13.7812 5.67188 15 8.01562 15C10.3281 15 12.4531 13.7812 13.6406 11.75C14.7969 9.75 14.7969 7.28125 13.6406 5.25C12.4531 3.25 10.3281 2 8.01562 2ZM8.01562 16.5C5.14062 16.5 2.51562 15 1.07812 12.5C-0.359375 10.0312 -0.359375 7 1.07812 4.5C2.51562 2.03125 5.14062 0.5 8.01562 0.5C10.8594 0.5 13.4844 2.03125 14.9219 4.5C16.3594 7 16.3594 10.0312 14.9219 12.5C13.4844 15 10.8594 16.5 8.01562 16.5ZM7.26562 11.25V9.25H5.26562C4.82812 9.25 4.51562 8.9375 4.51562 8.5C4.51562 8.09375 4.82812 7.75 5.26562 7.75H7.26562V5.75C7.26562 5.34375 7.57812 5 8.01562 5C8.42188 5 8.76562 5.34375 8.76562 5.75V7.75H10.7656C11.1719 7.75 11.5156 8.09375 11.5156 8.5C11.5156 8.9375 11.1719 9.25 10.7656 9.25H8.76562V11.25C8.76562 11.6875 8.42188 12 8.01562 12C7.57812 12 7.26562 11.6875 7.26562 11.25Z'
        fill='currentColor'
      />
    </Icon>
  );
};

PlusCircleIcon.propTypes = {
  boxSize: PropTypes.number,
  color: PropTypes.string,
};
