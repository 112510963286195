import { Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { useTranslations } from '~/hooks/useTranslations';

export const BrandFilter = ({ brandFilter, onChange, brandFilterOptions }) => {
  const { brandsTranslation } = useTranslations();

  const filterButtonText = useMemo(() => {
    for (let option of brandFilterOptions) {
      if (isEqual(brandFilter, option.value)) {
        return `${brandsTranslation.filters.BUTTON_PREFIX} ${option.display}`;
      }
    }
    return brandsTranslation.filters.VIEW_ALL;
  }, [brandFilter, brandFilterOptions, brandsTranslation]);

  const renderFilterOption = (option) => {
    const isSelected = isEqual(brandFilter, option.value);
    const handleClick = () => {
      let filter = { ...option.value };
      // revert filter if already selected
      if (isSelected) {
        for (let key in filter) {
          filter[key] = null;
        }
      }
      onChange(filter);
    };

    return (
      <MenuItem
        px={7}
        key={option.display}
        onClick={handleClick}
        fontWeight={isSelected ? 600 : 400}
        fontSize='sm'
        color={isSelected ? 'brand.primary' : 'neutral.primary'}
      >
        {option.display}
      </MenuItem>
    );
  };

  return (
    <Menu minWidth='unset' placement='bottom-end'>
      {({ isOpen }) => {
        return (
          <Fragment>
            <MenuButton
              color={isOpen ? 'brand.primary' : 'secondary.600'}
              fontWeight={isOpen ? 500 : 400}
              textDecorationColor='secondary.600'
              textDecoration={isOpen ? 'none' : 'underline'}
              textTransform='uppercase'
              fontSize='sm'
            >
              {filterButtonText}
            </MenuButton>
            <Portal>
              <MenuList minWidth='unset'>{brandFilterOptions.map(renderFilterOption)}</MenuList>
            </Portal>
          </Fragment>
        );
      }}
    </Menu>
  );
};

BrandFilter.propTypes = {
  brandFilter: PropTypes.object.isRequired,
  brandFilterOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
