import { Image, useBreakpointValue } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '~/hooks/useConfig';
import { BRANDS_ROUTE } from '~/lib/constants';
import { asPx } from '~/lib/helpers';
import { HEADER_ICON_HEIGHT } from '~/theme/constants';

const Logo = () => {
  const config = useConfig();
  const navigate = useNavigate();

  const responsive = useBreakpointValue(
    {
      base: {
        height: HEADER_ICON_HEIGHT,
        squareLogo: true,
      },
      sm: {
        height: HEADER_ICON_HEIGHT,
        squareLogo: true,
      },
      md: {
        height: HEADER_ICON_HEIGHT,
        squareLogo: false,
      },
    },
    { fallback: 'md', ssr: false }
  );

  const logoUrl = useMemo(() => {
    const { logo_img_url, logo_img_sq_url } = config.theme.images;

    if (responsive.squareLogo) {
      return logo_img_sq_url ?? logo_img_url;
    }
    return logo_img_url;
  }, [config, responsive]);

  return (
    <Image
      src={logoUrl}
      id='site-logo'
      role='button'
      tabIndex={0}
      onClick={() => navigate(BRANDS_ROUTE)}
      onKeyDown={({ key }) => key === 'Enter' && navigate(BRANDS_ROUTE)}
      cursor='pointer'
      flexShrink={0}
      height={asPx(responsive.height)}
      width='auto'
      alt='logo'
    />
  );
};

export default Logo;
