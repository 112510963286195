import { Alert, AlertIcon, AlertTitle, Box, Button, HStack, IconButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useAlertState } from '~/hooks/alerts/useAlertState';
import { getAlertId } from '~/lib/helpers';
import { colors } from '~/theme/default';

const MAX_STACK_DISTANCE = 11;
const MAX_ALERTS_DISPLAYED = 3;

const Alerts = ({ maxAlertsDisplayed = MAX_ALERTS_DISPLAYED, ...boxProps }) => {
  const { alerts } = useAlertState();

  const displayedAlerts = useMemo(
    () => alerts.slice(-maxAlertsDisplayed),
    [alerts, maxAlertsDisplayed]
  );

  if (displayedAlerts.length === 0) {
    return null;
  }

  return (
    <Box
      display='grid'
      gridTemplateColumns='1fr'
      gridTemplateRows='1fr'
      width='100%'
      alignItems='flex-start'
      {...boxProps}
    >
      {displayedAlerts.map((alert, i) => {
        const { status, description, primaryAction, secondaryAction } = alert;
        const Icon = alert.primaryAction?.icon;
        const { textColor, textHoverColor, iconBgColor, iconBgActiveColor, iconColor } =
          getAlertActionColors(alert);

        const styles = {
          zIndex: i,
          gridArea: '1 / 1 / 2 / 2',
          boxShadow: displayedAlerts.length > 1 ? '2px 2px 8px 0px #0000001F' : 'none',
          mt: `${MAX_STACK_DISTANCE * i}px`,
          transformOrigin: 'top',
          transform: `scale(${1 - (displayedAlerts.length - 1 - i) * 0.05})`,
          transition: 'transform 0.3s',
        };

        return (
          <Alert status={status} variant={status} key={getAlertId(alert)} sx={styles}>
            <HStack width='100%' justifyContent='space-between'>
              <HStack>
                <AlertIcon />
                <AlertTitle>{description}</AlertTitle>
              </HStack>
              <HStack spacing={10}>
                {!!secondaryAction && (
                  <Button
                    variant='link'
                    onClick={secondaryAction.action}
                    textDecoration='underline'
                    fontWeight={500}
                    backgroundColor='transparent'
                    color={textColor}
                    _hover={{ color: textHoverColor }}
                    _active={{ color: textHoverColor }}
                  >
                    {secondaryAction.text}
                  </Button>
                )}
                {!!primaryAction && (
                  <IconButton
                    minWidth='unset'
                    width={8}
                    height={8}
                    minHeight={8}
                    borderRadius='100%'
                    backgroundColor={iconBgColor}
                    _hover={{ backgroundColor: iconBgColor }}
                    _active={{ backgroundColor: iconBgActiveColor }}
                    onClick={primaryAction.action}
                    isRound={true}
                  >
                    <Icon color={iconColor} size={16} />
                  </IconButton>
                )}
              </HStack>
            </HStack>
          </Alert>
        );
      })}
    </Box>
  );
};

const getAlertActionColors = (alert) => {
  const { status = 'info' } = alert;
  switch (status) {
    case 'error':
      return {
        textColor: colors.red[500],
        textHoverColor: colors.red[700],
        iconBgColor: colors.red[100],
        iconBgActiveColor: colors.red[200],
        iconColor: colors.red[700],
      };
    case 'warning':
      return {
        textColor: colors.orange[700],
        textHoverColor: colors.orange[800],
        iconBgColor: colors.orange[200],
        iconBgActiveColor: colors.orange[300],
        iconColor: colors.orange[800],
      };
    case 'info':
      return {
        textColor: colors.blue[600],
        textHoverColor: colors.blue[700],
        iconBgColor: colors.blue[200],
        iconBgActiveColor: colors.blue[300],
        iconColor: colors.blue[800],
      };
    case 'success':
      return {
        textColor: colors.brand[700],
        textHoverColor: colors.brand[800],
        iconBgColor: colors.brand[200],
        iconBgActiveColor: colors.brand[300],
        iconColor: colors.brand[900],
      };
  }
};

Alerts.propTypes = {
  maxAlertsDisplayed: PropTypes.number,
  boxProps: PropTypes.object,
};

export default Alerts;
