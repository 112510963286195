export const accountTranslation = {
  transactions: 'Transactions',
  withdrawals: 'Withdrawals',
  transaction: {
    date: 'Date',
    offer: 'Offer',
    cardNumber: 'Card Number',
    amount: 'Amount',
    cashback: 'Cashback',
    status: 'Status',
    searchForTransactions: 'Search for transactions',
    seeMoreCta: 'Click here to see more',
  },
};
