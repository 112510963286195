import { listAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const variants = {
  menu: definePartsStyle(() => ({
    container: {
      bg: 'white',
    },
    item: {
      px: '20px',
      py: '8px',
      backgroundColor: 'white',
      fontSize: 'lg',
      _hover: {
        backgroundColor: 'secondary_scheme.100',
      },
    },
  })),
};

export const listTheme = defineMultiStyleConfig({ variants });
