export const IS_HTTP_URL_RE = /^http:\/\/|^https:\/\//;

export const URL_RE =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/i;

export const EMAIL_RE = /^.+@.{2,}\.\w{2,}$/;

export const NAME_RE = /^[a-zA-Z\- ']+$/;

export const SPECIAL_RE = /[$*\\.^[\]{}()?\-!@#%&/,><':;|_~+=]+/;
