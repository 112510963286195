import * as PropTypes from 'prop-types';

const RewardsIcon = ({ width, height, size, ...props }) => (
  <svg
    width={size ?? width ?? 24}
    height={size ?? height ?? 24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.79 21L3 11.21V13.21C3 13.74 3.21 14.25 3.59 14.62L11.38 22.41C12.16 23.19 13.43 23.19 14.21 22.41L20.42 16.2C21.2 15.42 21.2 14.15 20.42 13.37L12.79 21Z'
      fill='currentColor'
    />
    <path
      d='M12.79 21L3 11.21V13.21C3 13.74 3.21 14.25 3.59 14.62L11.38 22.41C12.16 23.19 13.43 23.19 14.21 22.41L20.42 16.2C21.2 15.42 21.2 14.15 20.42 13.37L12.79 21Z'
      fill='currentColor'
    />
    <path
      d='M11.38 17.41C11.77 17.8 12.28 18 12.79 18C13.3 18 13.81 17.8 14.2 17.41L20.41 11.2C21.19 10.42 21.19 9.15 20.41 8.37L12.62 0.58C12.25 0.21 11.74 0 11.21 0H5C3.9 0 3 0.9 3 2V8.21C3 8.74 3.21 9.25 3.59 9.62L11.38 17.41ZM5 2H11.21L19 9.79L12.79 16L5 8.21V2Z'
      fill='currentColor'
    />
    <path
      d='M11.38 17.41C11.77 17.8 12.28 18 12.79 18C13.3 18 13.81 17.8 14.2 17.41L20.41 11.2C21.19 10.42 21.19 9.15 20.41 8.37L12.62 0.58C12.25 0.21 11.74 0 11.21 0H5C3.9 0 3 0.9 3 2V8.21C3 8.74 3.21 9.25 3.59 9.62L11.38 17.41ZM5 2H11.21L19 9.79L12.79 16L5 8.21V2Z'
      fill='currentColor'
    />
    <path
      d='M7.25 5.5C7.94036 5.5 8.5 4.94036 8.5 4.25C8.5 3.55964 7.94036 3 7.25 3C6.55964 3 6 3.55964 6 4.25C6 4.94036 6.55964 5.5 7.25 5.5Z'
      fill='currentColor'
    />
    <path
      d='M7.25 5.5C7.94036 5.5 8.5 4.94036 8.5 4.25C8.5 3.55964 7.94036 3 7.25 3C6.55964 3 6 3.55964 6 4.25C6 4.94036 6.55964 5.5 7.25 5.5Z'
      fill='currentColor'
    />
  </svg>
);

RewardsIcon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default RewardsIcon;
