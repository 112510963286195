import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { res } from '~/lib/helpers';
import { MAJOR_HEAD, MINOR_HEAD, OFFER_BRAND, OFFER_HEAD } from '~/theme/constants';

/**
 * A NOTE ON FONT FAMILY
 * The default font of text in this site is Roboto.
 * If an element is set to fontFamily: 'display', the font family is set to Poppins.
 */

export const headingTheme = defineStyleConfig({
  defaultProps: {
    fontFamily: 'display',
    textAlign: 'left',
  },

  baseStyle: defineStyle({
    color: 'heading_color',
    colorScheme: 'heading_scheme',
  }),

  variants: {
    'brand-head': defineStyle({
      fontSize: '13pt',
      fontWeight: 600,
      numOfLines: 1,
      fontFamily: 'display',
      textTransform: 'uppercase',
      noOfLines: 1,
      color: 'blackAlpha.800',
      my: 2,
    }),
    'card-header': {
      fontFamily: 'display',
      fontSize: '20px',
      textAlign: 'left',
      fontWeight: 500,
      pt: '22px',
      pb: '8px',
      px: '34px',
      borderBottom: '2px solid',
      borderColor: 'blackAlpha.50',
      display: 'block',
    },
    'card-header-no-line': {
      fontFamily: 'display',
      fontSize: '20px',
      textAlign: 'left',
      fontWeight: 500,
      pt: '22px',
      pb: '8px',
      px: '34px',
      display: 'block',
    },
    'card-subheader': {
      fontFamily: 'display',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'left',
      fontWeight: 500,
      py: '16px',
      borderBottom: '1px solid',
      borderColor: 'blackAlpha.200',
      display: 'block',
      mb: '4px',
    },
    subhead: {
      fontFamily: 'display',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'left',
      fontWeight: 600,
      display: 'block',
    },
    'carousel-head': {
      fontSize: '20px',
      color: 'white',
      textShadow: '1px 1px 2px rgba(0,0,0,1);',
      fontFamily: 'display',
      fontWeight: 600,
    },
    'cash-back': defineStyle({
      ...MINOR_HEAD,
      color: 'brand.primary',
      fontFamily: 'display',
      textShadow: '2px 1px 1px rgba(220,255,255,1);',
      m: 0,
      p: 0,
    }),
    'category-head': defineStyle({
      ...MINOR_HEAD,
      fontFamily: 'display',
    }),
    'homepage-head': defineStyle({
      fontSize: '28px',
      textAlign: 'left',
      fontFamily: 'display',
      fontWeight: 600,
      my: 2,
      pt: ['24px', '26px', '27px'],
      pb: '16px',
    }),
    'landing-gs': {
      ...MINOR_HEAD,
      fontSize: ['24px', '27.5px', '30px'],
      textAlign: 'center',
      color: 'blackAlpha.800',
      mt: ['5px', '5px', '65px'],
      mb: [0, 0, '11px'],
      p: 0,
    },
    'landing-panel': {
      ...MAJOR_HEAD,
      fontSize: ['24px', '36px', '60px'],
      lineHeight: 1.5,
      mt: ['20px', '40px', 'min(20vh,200px)'],
      mb: ['20px', '40px', 'min(10vh, 100px)'],
    },
    'landing-plug': {
      fontSize: res({ base: 20, lg: 30, xl: 45 }, true),
    },
    'landing-question': {
      fontSize: res({ base: 16, md: 18, lg: 20, xl: 30 }, true),
    },
    lp: {
      color: 'white',
      fontFamily: 'display',
      fontSize: ['30px', '30px', '45px', '65px'],
      lineHeight: '1.1',
      mb: [0, 0, '24px'],
      mt: [0, 0, '50px', '50px', 0],
    },
    lp1: {
      color: 'white',
      fontFamily: 'display',
      textAlign: ['center', 'center', 'left', 'left'],
      fontSize: ['30px', '30px', '30px', '45px', '65px'],
      lineHeight: '1.1',
      mb: ['16px', '16px', '16px'],
      mt: [0, 0, '50px', '50px', 0],
    },
    offer: defineStyle({
      ...OFFER_HEAD,
      fontFamily: 'display',
    }),
    'offer-brand': defineStyle({
      ...OFFER_BRAND,
    }),
    'offer-main': {
      fontFamily: 'display',
      textAlign: 'center',
      fontSize: '28px',
      color: 'secondary_color',
    },
    'page-head': defineStyle({
      ...MAJOR_HEAD,
      fontFamily: 'display',
    }),
    'page-head-minor': defineStyle({
      ...MINOR_HEAD,
      fontFamily: 'display',
    }),
    prompt: defineStyle({
      ...MINOR_HEAD,
      fontFamily: 'display',
      fontStyle: 'italic',
    }),
    'related-offer': {
      fontFamily: 'display',
      fontSize: res({ base: 16, md: 20 }, true),
      textAlign: 'left',
      fontWeight: 600,
      pb: res({ base: 16, md: 24 }, true),
    },
    'rewards-box': {
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'center',
      color: 'secondary_color',
    },
  },
});
