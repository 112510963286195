import { merge } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { BadStatusError } from '~/lib/errors';
import { responseStatusIsGood } from '~/lib/helpers';

const APPCONFIG_ENDPOINT = 'https://sbox-connect-appconfig.finfare.com/flags';
const TWO_MINUTE_MS = 1000 * 60 * 2;

export const FLAGS = {};
FLAGS.GOOGLE_ANALYTICS_IS_LIVE = 'rewards_google-analytics-is-live';
FLAGS.NUVEI_IS_LIVE = 'connect_nuvei-is-live';

const DEFAULT_FLAGS = {
  [FLAGS.GOOGLE_ANALYTICS_IS_LIVE]: { enabled: false },
  [FLAGS.NUVEI_IS_LIVE]: { enabled: false },
};

const FLAG_QUERY_PARAM = 'ff';
const FLAG_SEPARATOR = ';';
const VALUE_SEPARATOR = ':';

const parseStringToBoolean = (value) => {
  switch (value.toLowerCase()) {
    case 'true':
    case '1':
      return true;
    default:
      return false;
  }
};

/**
 * A hook for reading one or all registered feature flags.
 *
 * @param {string} [flagKey] - An optional key representing the flag whose status you want to read.
 * @returns {Object<string, { enabled: boolean }> | { enabled: boolean }} -
 * Either an object with string keys and values containing an `enabled` boolean,
 * or a single object with an `enabled` boolean.
 */
export const useFeatureFlag = (flagKey) => {
  const fetchUrl = useFetchUrl();
  let [searchParams] = useSearchParams();
  const [flags, setFlags] = useState(DEFAULT_FLAGS);

  const mergeWithQueryParams = useCallback(
    (flagData) => {
      const ffParams = searchParams.get(FLAG_QUERY_PARAM);
      const queryParams = ffParams?.split(FLAG_SEPARATOR);

      const newFlags = { ...flagData };
      queryParams?.forEach((params) => {
        const [paramKey, paramValue] = params.split(VALUE_SEPARATOR);
        if (flagData[paramKey] != null) {
          newFlags[paramKey].enabled = parseStringToBoolean(paramValue);
        }
      });
      return newFlags;
    },
    [searchParams]
  );

  useEffect(() => {
    let timeoutId = null;

    const fetchProjectConfig = async () => {
      try {
        // use native browser fetch; request does not require any auth tokens
        const response = await fetch(APPCONFIG_ENDPOINT);

        if (!responseStatusIsGood(response)) {
          throw new BadStatusError(response);
        }

        const data = await response.json();

        const updatedFlags = mergeWithQueryParams(data);
        // If key is not defined in AWS AppConfig, keep default value instead of removing key from `flags`
        setFlags((prev) => merge(prev, updatedFlags));
      } catch (error) {
        console.error(error);
      }

      timeoutId = setTimeout(fetchProjectConfig, TWO_MINUTE_MS);
    };

    fetchProjectConfig().catch(/* do nothing */);

    return () => clearTimeout(timeoutId);
  }, [flags, mergeWithQueryParams, searchParams, fetchUrl]);

  return flagKey ? flags[flagKey] : flags;
};
