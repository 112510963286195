import { Box, Button, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useTranslations } from '~/hooks/useTranslations';
import { TransactionCard } from '~/pages/account/transactions/transaction-card';
export function TransactionCards({ showMore, transactions, onShowMore }) {
  const { accountTranslation: t } = useTranslations();
  return (
    <VStack spacing={4} p={4}>
      {transactions.map((t) => (
        <TransactionCard key={t.uuid} transaction={t} />
      ))}
      {showMore && (
        <Box p={6} width='fit-content' mx='auto'>
          <Button
            variant='unstyled'
            onClick={onShowMore}
            backgroundColor='brand.primary'
            color='white'
            p={3}
            lineHeight={1}
          >
            {t.seeMoreCta}
          </Button>
        </Box>
      )}
    </VStack>
  );
}

TransactionCards.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
  showMore: PropTypes.bool,
  onShowMore: PropTypes.func,
};
