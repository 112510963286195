import {
  Button,
  Center,
  Checkbox,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorRow } from '~/components/common/error-row';
import { useMarketingConsent } from '~/hooks/useMarketingConsent';
import { usePdfLocations } from '~/hooks/usePdfLocations';
import { useTerms } from '~/hooks/useTerms';
import { useTranslations } from '~/hooks/useTranslations';

const initialValues = {
  terms: true,
  marketing: true,
};

export function TermsModal() {
  const { currentMarketingText, loadingMarketingText, setMarketingPreference } =
    useMarketingConsent();
  const { genericTranslation } = useTranslations();
  const { acceptTerms, termsModalIsOpen } = useTerms();

  const pdfLocations = usePdfLocations();

  const validationSchema = Yup.object().shape({
    terms: Yup.boolean().oneOf([true], genericTranslation.validation.termsRequired),
    marketing: Yup.boolean(),
  });

  const onSubmit = useCallback(
    ({ marketing }) => {
      setMarketingPreference(marketing);
      acceptTerms();
    },
    [acceptTerms, setMarketingPreference]
  );

  const { handleSubmit, values, handleChange, errors } = useFormik({
    isInitialValid: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const disabledSubmit = loadingMarketingText || Object.keys(errors).length;

  return (
    <Modal isOpen={termsModalIsOpen} size='xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent maxWidth={600}>
        <ModalHeader textAlign='center' mb={0}>
          Review and Accept
        </ModalHeader>
        <Divider />
        <ModalBody py={6}>
          <form onSubmit={handleSubmit}>
            <VStack>
              <VStack alignItems='flex-start'>
                <HStack>
                  <Checkbox
                    id='terms'
                    name='terms'
                    type='checkbox'
                    spacing={4}
                    isChecked={values.terms}
                    onChange={handleChange}
                  >
                    <Text as='span'>
                      {`By checking this box, I acknowledge and agree to the `}
                      <Link to={pdfLocations.TERMS_AND_CONDITIONS} target='_blank'>
                        <Text as='span' display='inline' color='secondary_color' m={0} p={0}>
                          Terms & Conditions
                        </Text>
                      </Link>
                      .
                    </Text>
                  </Checkbox>
                </HStack>
                <ErrorRow isPresent={Boolean(errors['terms'])} ml={8}>
                  {errors['terms']}
                </ErrorRow>
              </VStack>
              {loadingMarketingText && (
                <Center>
                  <Spinner />
                </Center>
              )}
              {currentMarketingText && (
                <VStack alignItems='flex-start'>
                  <HStack>
                    <Checkbox
                      id='marketing'
                      name='marketing'
                      type='checkbox'
                      spacing={4}
                      isChecked={values.marketing}
                      onChange={handleChange}
                    >
                      <Text as='span'>{currentMarketingText}</Text>
                    </Checkbox>
                  </HStack>
                  <ErrorRow isPresent={Boolean(errors['marketing'])} ml={8}>
                    {errors['marketing']}
                  </ErrorRow>
                </VStack>
              )}
              <Button type='submit' mt={4} onClick={handleSubmit} isDisabled={disabledSubmit}>
                Continue
              </Button>
              <Link to={pdfLocations.PRIVACY_POLICY} target='_blank'>
                <Text as='span' color='secondary_color'>
                  Privacy Policy
                </Text>
              </Link>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
