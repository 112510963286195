const DeleteIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <g opacity={0.8}>
      <rect width={24} height={24} fill='#FEEFEB' rx={12} />
      <path
        fill='#C24A2E'
        d='M8.666 18c-.367 0-.68-.13-.941-.391a1.285 1.285 0 0 1-.392-.942V8a.647.647 0 0 1-.476-.191.647.647 0 0 1-.191-.476c0-.189.064-.347.191-.475a.647.647 0 0 1 .476-.191h2.666c0-.19.064-.348.192-.476A.645.645 0 0 1 10.666 6h2.667c.189 0 .347.064.475.191a.647.647 0 0 1 .191.476h2.667c.189 0 .347.063.475.191a.646.646 0 0 1 .192.475.646.646 0 0 1-.192.476.645.645 0 0 1-.475.191v8.667c0 .366-.13.68-.391.942-.262.26-.576.391-.942.391H8.666Zm0-10v8.667h6.667V8H8.666Zm1.333 6.667c0 .189.064.347.192.474a.644.644 0 0 0 .475.192.645.645 0 0 0 .475-.192.644.644 0 0 0 .192-.474V10a.647.647 0 0 0-.192-.475.647.647 0 0 0-.475-.192.645.645 0 0 0-.475.192A.646.646 0 0 0 10 10v4.667Zm2.667 0c0 .189.064.347.192.474a.644.644 0 0 0 .475.192.645.645 0 0 0 .475-.192.644.644 0 0 0 .191-.474V10a.647.647 0 0 0-.191-.475.647.647 0 0 0-.475-.192.645.645 0 0 0-.475.192.646.646 0 0 0-.192.475v4.667Z'
      />
    </g>
  </svg>
);
export default DeleteIcon;
