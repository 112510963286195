import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const MoneyShieldIcon = ({ boxSize = 4, color = 'currentColor', ...props }) => {
  return (
    <Icon boxSize={boxSize} viewBox='0 0 26 32' fill='none' color={color} {...props}>
      <path
        d='M12.6281 30C12.5186 30 12.409 29.9804 12.306 29.9395C5.50331 27.2975 2.43302 23.2872 1.05481 20.3886C0.356721 18.9204 0.00195312 17.2839 0.00195312 15.5281V4.74932C0.00195312 4.25722 0.400863 3.85831 0.892961 3.85831C6.27498 3.85831 10.4227 1.2327 11.9823 0.243597L12.1524 0.13733C12.4434 -0.0457766 12.8129 -0.0457766 13.1022 0.13733L13.2739 0.245232C14.8352 1.2327 18.9812 3.85831 24.3633 3.85831C24.8554 3.85831 25.2543 4.25722 25.2543 4.74932V15.5264C25.2543 17.2839 24.8995 18.9188 24.2014 20.3869C22.8232 23.2856 19.7529 27.2975 12.9502 29.9379C12.8455 29.9787 12.7376 29.9984 12.6281 29.9984V30ZM1.78397 5.61907V15.5264C1.78397 17.0158 2.07988 18.394 2.66353 19.6202C3.88152 22.182 6.59868 25.7199 12.6281 28.1477C18.6592 25.7215 21.3747 22.1837 22.5927 19.6202C23.1763 18.394 23.4723 17.0158 23.4723 15.5264V5.61907C18.3486 5.36567 14.3055 2.99673 12.6281 1.94714C10.9507 2.99673 6.90768 5.36567 1.78397 5.61907Z'
        fill='currentColor'
      />
      <path
        d='M13.3746 21.9286H11.8804C9.64222 21.9286 7.82097 20.1074 7.82097 17.8692C7.82097 17.3771 8.21988 16.9782 8.71198 16.9782C9.20408 16.9782 9.60299 17.3771 9.60299 17.8692C9.60299 19.1248 10.6248 20.145 11.8787 20.145H13.373C14.6286 20.145 15.6488 19.1248 15.6488 17.8692C15.6488 16.6136 14.6286 15.5918 13.373 15.5918H11.8787C9.64059 15.5918 7.81934 13.7706 7.81934 11.5324C7.81934 9.29428 9.64059 7.47302 11.8787 7.47302H13.373C15.6112 7.47302 17.4324 9.29428 17.4324 11.5324C17.4324 12.0245 17.0335 12.4234 16.5414 12.4234C16.0493 12.4234 15.6504 12.0245 15.6504 11.5324C15.6504 10.2768 14.6302 9.25667 13.3746 9.25667H11.8804C10.6248 9.25667 9.60462 10.2768 9.60462 11.5324C9.60462 12.788 10.6264 13.8082 11.8804 13.8082H13.3746C15.6128 13.8082 17.434 15.6294 17.434 17.8692C17.434 20.109 15.6128 21.9286 13.3746 21.9286Z'
        fill='currentColor'
      />
      <path
        d='M12.6273 23.4179C12.1352 23.4179 11.7363 23.019 11.7363 22.5269V21.0375C11.7363 20.5454 12.1352 20.1465 12.6273 20.1465C13.1194 20.1465 13.5183 20.5454 13.5183 21.0375V22.5269C13.5183 23.019 13.1194 23.4179 12.6273 23.4179Z'
        fill='currentColor'
      />
      <path
        d='M12.6273 9.25662C12.1352 9.25662 11.7363 8.85771 11.7363 8.36561V6.87624C11.7363 6.38414 12.1352 5.98523 12.6273 5.98523C13.1194 5.98523 13.5183 6.38414 13.5183 6.87624V8.36561C13.5183 8.85771 13.1194 9.25662 12.6273 9.25662Z'
        fill='currentColor'
      />
    </Icon>
  );
};

MoneyShieldIcon.propTypes = {
  boxSize: PropTypes.number,
  color: PropTypes.string,
};
