import { useCallback, useEffect, useState } from 'react';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { MARKETING_URL } from '~/lib/constants';
import { useAccountState } from './account/useAccountState';

export const useMarketingConsent = () => {
  const fetchUrl = useFetchUrl();
  const [currentMarketingText, setCurrentMarketingText] = useState(null);
  const [revision, setRevision] = useState(null);
  const [loadingMarketingText, setLoadingMarketingText] = useState(true);
  const { isLoggedIn } = useAccountState();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetchUrl(MARKETING_URL);

        const data = await response.json();

        setRevision(data?.marketing_revision_id ?? null);
        setCurrentMarketingText(data?.text ?? '');
        setLoadingMarketingText(false);
      } catch {
        setCurrentMarketingText('');
        setLoadingMarketingText(false);
      }
    };

    if (isLoggedIn && currentMarketingText === null) {
      fetchConfig().then();
    }
  }, [currentMarketingText, isLoggedIn, fetchUrl]);

  const setMarketingPreference = useCallback(
    (pref = false) => {
      if (revision === null) {
        return;
      }

      fetchUrl(MARKETING_URL, {
        body: JSON.stringify({ marketing_agreement_id: revision, accepted: pref }),
        method: 'POST',
      });
    },
    [revision, fetchUrl]
  );

  return {
    currentMarketingText,
    loadingMarketingText,
    setMarketingPreference,
  };
};
