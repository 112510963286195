import { Tab, TabList, TabPanel, TabPanels, Tabs, useBreakpointValue } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useTranslations } from '~/hooks/useTranslations';
import { TransactionCards } from '~/pages/account/transactions/transaction-cards';
import { TransactionsTable } from '~/pages/account/transactions/transactions-table';
import { WithdrawalsCard, WithdrawalsTable } from '~/pages/account/withdrawals';
import TransactionSearch from './transactions/transaction-search';

const AccountTabs = ({
  narrow,
  transactions,
  searchFieldPhrase,
  startDate,
  endDate,
  onSearchFieldChange,
  onDateChange,
  onResetDate,
  withdrawals,
  showMore,
  onShowMore,
  onReset,
}) => {
  const { accountTranslation: t } = useTranslations();
  const resp = useBreakpointValue({
    base: {
      padding: 4,
    },
    lg: {
      padding: 6,
    },
  });
  return (
    <Tabs>
      <TabList>
        <Tab>{t.transactions}</Tab>
        <Tab>{t.withdrawals}</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <TransactionSearch
            padding={resp.padding}
            searchFieldPhrase={searchFieldPhrase}
            startDate={startDate}
            endDate={endDate}
            onSearchFieldChange={onSearchFieldChange}
            onDateChange={onDateChange}
            onResetDate={onResetDate}
            onReset={onReset}
          />

          {narrow ? (
            <TransactionCards {...{ transactions, showMore, onShowMore }} />
          ) : (
            <TransactionsTable {...{ transactions, showMore, onShowMore }} />
          )}
        </TabPanel>
        <TabPanel>
          {narrow ? (
            <WithdrawalsCard withdrawals={withdrawals} />
          ) : (
            <WithdrawalsTable withdrawals={withdrawals} />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

AccountTabs.propTypes = {
  narrow: PropTypes.bool,
  withdrawals: PropTypes.arrayOf(PropTypes.object),
  transactions: PropTypes.arrayOf(PropTypes.object),
  searchFieldPhrase: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  showMore: PropTypes.bool,
  onShowMore: PropTypes.func,
  onSearchFieldChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onResetDate: PropTypes.func,
  onReset: PropTypes.func,
};

export default AccountTabs;
