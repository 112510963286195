import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  cardAnatomy.keys
);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    borderRadius: '20px',
    p: 0,
    py: 0,
    shadow: 'lg',
  },
  header: {
    fontFamily: 'display',
    fontSize: '20px',
    textAlign: 'left',
    fontWeight: 500,
    pt: '22px',
    pb: '8px',
    px: '34px',
    borderBottom: '2px solid',
    borderColor: 'blackAlpha.50',
    m: 0,
  },
  body: {
    p: 0,
  },
  footer: {},
});

const variants = {
  /**   funky: definePartsStyle({
    container: {
      borderColor: 'red',
      borderWidth: '3px',
    },
  }),
  */
};
export const cardTheme = defineMultiStyleConfig({ baseStyle, variants });
