import {
  Box,
  Button,
  CircularProgress,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { PageContainer } from '~/components/page-container';
import { useSearch } from '~/hooks/useSearch';
import { useTranslations } from '~/hooks/useTranslations';
import { Brand } from '~/pages/brands/brand';

export function SearchList() {
  const { genericTranslation: t, brandsTranslation: b } = useTranslations();
  const {
    activeCategory,
    brands,
    searchPhrase,
    showMore,
    onMore,
    isBrandsLoading,
    isCategoriesLoading,
  } = useSearch();

  const responsive = useBreakpointValue(
    {
      base: { columns: 1 },
      sm: { columns: 2 },
      md: { columns: 3 },
      lg: { columns: 4 },
      xl: { columns: 4 },
    },
    {
      fallback: 'md',
      ssr: false,
    }
  );

  const searchLabel = useMemo(() => {
    if (searchPhrase?.trim()) {
      return activeCategory
        ? b.searchResultsForIn(searchPhrase, activeCategory.category)
        : b.searchResultsFor(searchPhrase);
    }
    return activeCategory ? b.searchResultsIn(activeCategory.category) : b.searchResults;
  }, [activeCategory, searchPhrase, b]);

  const brandItems = Array.from(brands.values());
  const isLoading = (isBrandsLoading || isCategoriesLoading) && !brandItems.length;
  const noBrandsFound = !isBrandsLoading && !isCategoriesLoading && brands && !brandItems.length;

  return (
    <PageContainer>
      <Heading
        sx={{
          alignSelf: 'flex-start',
          fontSize: '1.75rem',
          color: 'neutral.primary',
          textAlign: 'start',
          marginBottom: 7,
        }}
      >
        {searchLabel}
      </Heading>
      {isLoading ? (
        <Box>
          <CircularProgress size={8} isIndeterminate color='brand.primary' />
        </Box>
      ) : (
        <SimpleGrid columns={responsive.columns} spacing={5} width='100%'>
          {brandItems.map((brand) => (
            <Brand key={brand.brand_uid} brand={brand} />
          ))}
        </SimpleGrid>
      )}
      {showMore && !isLoading && (
        <Button isDisabled={isLoading} onClick={onMore} alignSelf='center' marginTop={16}>
          {isBrandsLoading ? <Spinner color='white' /> : t.loadMore}
        </Button>
      )}
      {noBrandsFound && (
        <Box>
          <Text>{b.noBrands}</Text>
        </Box>
      )}
    </PageContainer>
  );
}
