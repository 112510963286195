import { useContext } from 'react';
import { FinanceContext } from '~/lib/state/contexts/FinanceContext';

export const useFinance = () => {
  const values = useContext(FinanceContext);

  if (!values) {
    throw new Error('useFinance must be used within FinanceProvider');
  }

  return values;
};
