import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import color from 'color';
import * as PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useConfig } from '~/hooks/useConfig';
import { defaultTheme, findKeepLoveTheme } from '~/theme';

const FIND_KEEP_LOVE = 'FindKeepLove';
const COLORS = [100, 200, 300, 400, 500, 600, 700, 800];
const COLOR_UPDATES = {
  800: { darken: 0.6 },
  700: { darken: 0.4 },
  600: { darken: 0.2 },
  500: {},
  400: { lighten: 0.333, desaturate: 0.1 },
  300: { lighten: 0.666, desaturate: 0.25 },
  200: { lighten: 1, desaturate: 0.333 },
  100: { lighten: 1.5, desaturate: 0.666 },
};

function colorRange(colorDefinition) {
  if (!colorDefinition) {
    return {};
  }
  const items = {};
  if (/\./.test(colorDefinition)) {
    const [base] = colorDefinition.split('.');
    COLORS.forEach((name) => {
      items[name] = base + '.' + name;
    });
    return items;
  }

  let baseColor;
  try {
    baseColor = color(colorDefinition);
  } catch (err) {
    return items;
  }

  COLORS.forEach((name) => {
    if (!COLOR_UPDATES[name]) {
      console.error('cannot get color update', name, 'from', COLOR_UPDATES);
      return;
    }
    const { darken, lighten, desaturate } = COLOR_UPDATES[name];

    let color = baseColor;
    if (darken) {
      color = color.darken(darken);
    }
    if (lighten) {
      color = color.lighten(lighten);
    }
    if (desaturate) {
      color = color.desaturate(desaturate);
    }
    items[name] = color.rgb().toString();
  });

  return items;
}

export const Chakra = ({ children }) => {
  const {
    publisher: { publisher_name },
    theme,
  } = useConfig();

  const themeExtension = useMemo(() => {
    if (!theme?.colors) {
      return {};
    }
    try {
      return {
        colors: {
          ...theme.colors,
          heading_scheme: colorRange(theme.colors.heading_color),
          secondary_scheme: colorRange(theme.colors.secondary_color),
        },
      };
    } catch (err) {
      console.error('cannot compute theme update', err.message);
      return {};
    }
  }, [theme]);

  let publisherTheme;

  switch (publisher_name) {
    case FIND_KEEP_LOVE:
      publisherTheme = findKeepLoveTheme;
      break;
    default:
      publisherTheme = defaultTheme;
      break;
  }

  return (
    <>
      <ColorModeScript initialColorMode={publisherTheme.config.initialColorMode} />
      <ChakraProvider theme={extendTheme(publisherTheme, themeExtension)}>
        {children}
      </ChakraProvider>
    </>
  );
};

Chakra.propTypes = {
  children: PropTypes.node,
};
