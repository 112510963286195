import { useContext } from 'react';
import { AccountStateContext } from '~/lib/state/contexts/AccountContext/AccountContext';

export const useAccountState = () => {
  const accountState = useContext(AccountStateContext);
  if (!accountState) {
    throw new Error('useAccountState must be used within AccountProvider');
  }
  return accountState;
};
