import { useCallback } from 'react';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { CASHBACK_REDEMPTION_REQUEST, REWARDS_SUMMARY } from '~/lib/constants';

export const useRewardsApi = () => {
  const fetchUrl = useFetchUrl();

  const submitRedemptionRequest = useCallback(
    async ({
      accountNumber,
      routingNumber,
      accountHolderName,
      accountType,
      redemptionAmount,
      region,
    }) => {
      try {
        const resp = await fetchUrl(CASHBACK_REDEMPTION_REQUEST, {
          body: JSON.stringify({
            account_number: accountNumber,
            routing_number: routingNumber,
            account_holder_name: accountHolderName,
            account_type: accountType,
            redemption_amount: redemptionAmount,
            ach_authorization: true,
            region_code: region,
          }),
          method: 'POST',
        });

        return await resp.json();
      } catch (error) {
        console.log('redemption request error:', error);
        throw error;
      }
    },
    [fetchUrl]
  );

  const getRewardsSummary = useCallback(async () => {
    try {
      const result = await fetchUrl(REWARDS_SUMMARY);

      return await result.json();
    } catch (error) {
      console.log('rewards summary error:', error);
      throw error;
    }
  }, [fetchUrl]);

  return { getRewardsSummary, submitRedemptionRequest };
};
