import { handleBadStatusError,isBadStatusError } from './handleBadStatusError';
import { CustomError, handleCustomError, isCustomError } from './handleCustomError';
import { handleSilentError, isSilentError } from './handleSilentError';

export const DEFAULT_TITLE = 'Unknown Error';

/**
 *
 * @param {*} error
 * @returns {boolean}
 * @description Returns true if the argument is a direct instance of Error, and not any of its subclasses.
 */
export const isGenericError = (error) => {
  return error.constructor.name === 'Error';
};

/**
 * @param {Error | CustomError | BadStatusError | SilentError} error
 * @param {string} title A semi-specific title which can be applied to all errors which may arise for a given request
 * @description Base function for handling errors. It delegates to more specialized
 * handler functions depending on the type of error passed in.
 */
export const handleError = (error, title = DEFAULT_TITLE) => {
  if (isBadStatusError(error)) {
    handleBadStatusError(error, title);
  } else if (isCustomError(error)) {
    handleCustomError(error, title);
  } else if (isSilentError(error)) {
    handleSilentError();
  } else {
    handleCustomError(
      new CustomError({
        title,
        description: error?.message,
      })
    );
  }
};
