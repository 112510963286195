import { Heading, Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useAccountState } from '~/hooks/account/useAccountState';

export function UserInfo({ narrow }) {
  const { userEmail } = useAccountState();

  return (
    <VStack
      width='100%'
      flexBasis={narrow ? undefined : '50%'}
      alignItems='stretch'
      data-role='user-Vstack'
      spacing='16px'
      px='20px'
    >
      <Heading textAlign='center' pt={0} variant='card-header-no-line'>
        User <br />
        <Text fontSize='sm'>{userEmail}</Text>
      </Heading>
    </VStack>
  );
}

UserInfo.propTypes = {
  narrow: PropTypes.bool,
};
