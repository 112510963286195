import { Box } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ children, sliderRef, ...props }) => {
  const settings = {
    arrows: false,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    ...props,
  };

  const setSliderRef = (element) => {
    if (sliderRef) {
      sliderRef.current = element;
    }
  };

  return (
    <Box width='100%' data-role='slider-container'>
      <Slider ref={setSliderRef} {...settings}>
        {children}
      </Slider>
    </Box>
  );
};

Carousel.displayName = 'Carousel';

export default Carousel;

Carousel.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
  sliderRef: PropTypes.object,
};
