export function FinfareLogoIcon(props) {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 130 37'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      xmlSpace='preserve'
      fillRule='evenodd'
      clipRule='evenodd'
      strokeLinejoin='round'
      strokeMiterlimit={2}
      {...props}
    >
      <g id='Seal---Name'>
        <clipPath id='_clip1'>
          <rect x='0' y='0.5' width='130' height='36' />
        </clipPath>
        <g clipPath='url(#_clip1)'>
          <path
            id='Vector'
            d='M23.964,35.414C33.238,32.019 38.012,21.689 34.631,12.342C31.274,3.007 21.024,-1.804 11.75,1.592C2.488,4.987 -2.286,15.318 1.083,24.652C4.453,33.999 14.703,38.81 23.964,35.414ZM26.298,6.151C27.334,6.859 28.262,7.699 29.095,8.659L14.119,14.154L11.024,15.294L12.155,18.413L13.286,21.533L14.417,24.652L17.512,23.512L32.738,17.933C32.786,19.169 32.679,20.393 32.429,21.581L18.631,26.644L15.536,27.784L16.667,30.903L17.607,33.507C16.345,33.483 15.107,33.303 13.905,32.967L6.798,13.302L26.298,6.151ZM31.845,13.374C31.988,13.77 32.119,14.178 32.226,14.586L16.381,20.405L15.25,17.285L31.012,11.502C31.334,12.09 31.607,12.714 31.845,13.374ZM20.988,33.171L19.762,29.763L30.941,25.66C29.262,28.767 26.512,31.299 22.953,32.595C22.298,32.835 21.643,33.027 20.988,33.171ZM22.155,4.135L5.417,10.278C7.119,7.663 9.631,5.563 12.774,4.411C15.917,3.259 19.179,3.235 22.155,4.135ZM3.607,14.178L9.703,31.035C7.107,29.319 5.024,26.788 3.881,23.62C2.738,20.453 2.714,17.177 3.607,14.178Z'
            fill='url(#_Linear2)'
            fillRule='nonzero'
          />
          <path
            id='Vector_2'
            d='M56.292,2.873L56.292,5.104L49.178,5.104L49.178,10.022L54.725,10.022L54.725,12.253L49.178,12.253L49.178,19.547L46.429,19.547L46.429,2.873L56.292,2.873Z'
            fill='rgb(68,68,68)'
            fillRule='nonzero'
          />
          <path
            id='Vector_3'
            d='M58.675,4.072C58.338,3.736 58.169,3.316 58.169,2.825C58.169,2.333 58.338,1.913 58.675,1.577C59.013,1.241 59.435,1.073 59.929,1.073C60.424,1.073 60.822,1.241 61.159,1.577C61.497,1.913 61.666,2.333 61.666,2.825C61.666,3.316 61.497,3.736 61.159,4.072C60.822,4.408 60.412,4.576 59.929,4.576C59.447,4.576 59.013,4.408 58.675,4.072ZM61.28,6.327L61.28,19.547L58.531,19.547L58.531,6.327L61.28,6.327Z'
            fill='rgb(68,68,68)'
            fillRule='nonzero'
          />
          <path
            id='Vector_4'
            d='M74.001,6.76C74.834,7.191 75.473,7.827 75.943,8.679C76.413,9.531 76.642,10.55 76.642,11.75L76.642,19.547L73.917,19.547L73.917,12.158C73.917,10.97 73.616,10.07 73.025,9.435C72.434,8.799 71.614,8.487 70.589,8.487C69.564,8.487 68.744,8.799 68.141,9.435C67.538,10.07 67.237,10.97 67.237,12.158L67.237,19.547L64.487,19.547L64.487,6.328L67.237,6.328L67.237,7.839C67.683,7.299 68.262,6.868 68.961,6.568C69.661,6.268 70.408,6.112 71.192,6.112C72.229,6.112 73.17,6.328 74.001,6.76Z'
            fill='rgb(68,68,68)'
            fillRule='nonzero'
          />
          <path
            id='Vector_5'
            d='M85.3,8.559L82.84,8.559L82.84,19.547L80.067,19.547L80.067,8.559L78.499,8.559L78.499,6.328L80.067,6.328L80.067,5.392C80.067,3.868 80.477,2.765 81.285,2.069C82.093,1.373 83.371,1.025 85.107,1.025L85.107,3.305C84.275,3.305 83.684,3.461 83.347,3.772C83.009,4.084 82.84,4.624 82.84,5.392L82.84,6.328L85.3,6.328L85.3,8.559Z'
            fill='rgb(68,68,68)'
            fillRule='nonzero'
          />
          <path
            id='Vector_6'
            d='M87.241,9.351C87.796,8.331 88.556,7.527 89.508,6.964C90.461,6.4 91.522,6.112 92.68,6.112C93.729,6.112 94.633,6.316 95.417,6.724C96.201,7.131 96.816,7.635 97.286,8.247L97.286,6.328L100.059,6.328L100.059,19.547L97.286,19.547L97.286,17.58C96.816,18.204 96.189,18.72 95.381,19.14C94.573,19.559 93.656,19.763 92.632,19.763C91.486,19.763 90.449,19.475 89.496,18.888C88.544,18.3 87.796,17.484 87.241,16.44C86.687,15.397 86.409,14.209 86.409,12.878C86.409,11.546 86.687,10.382 87.241,9.351ZM96.719,10.55C96.345,9.879 95.851,9.363 95.236,9.015C94.621,8.667 93.97,8.487 93.259,8.487C92.547,8.487 91.896,8.655 91.281,9.003C90.666,9.351 90.172,9.855 89.798,10.514C89.424,11.174 89.231,11.966 89.231,12.878C89.231,13.789 89.424,14.593 89.798,15.277C90.172,15.961 90.678,16.488 91.293,16.848C91.908,17.208 92.571,17.388 93.259,17.388C93.946,17.388 94.621,17.208 95.236,16.86C95.851,16.512 96.345,15.996 96.719,15.313C97.093,14.629 97.286,13.837 97.286,12.926C97.286,12.014 97.093,11.222 96.719,10.55Z'
            fill='rgb(68,68,68)'
            fillRule='nonzero'
          />
          <path
            id='Vector_7'
            d='M107.62,6.676C108.283,6.304 109.079,6.112 109.995,6.112L109.995,8.943L109.296,8.943C108.223,8.943 107.403,9.219 106.848,9.759C106.293,10.298 106.016,11.246 106.016,12.59L106.016,19.547L103.267,19.547L103.267,6.328L106.016,6.328L106.016,8.247C106.414,7.575 106.945,7.06 107.62,6.676Z'
            fill='rgb(68,68,68)'
            fillRule='nonzero'
          />
          <path
            id='Vector_8'
            d='M124.2,13.957L114.047,13.957C114.131,15.013 114.517,15.865 115.229,16.5C115.94,17.136 116.808,17.46 117.833,17.46C119.316,17.46 120.353,16.848 120.968,15.613L123.935,15.613C123.537,16.824 122.801,17.82 121.752,18.6C120.703,19.379 119.389,19.763 117.833,19.763C116.567,19.763 115.422,19.475 114.421,18.912C113.42,18.348 112.624,17.544 112.057,16.512C111.491,15.481 111.201,14.281 111.201,12.926C111.201,11.57 111.479,10.37 112.033,9.339C112.588,8.307 113.372,7.515 114.372,6.952C115.373,6.388 116.531,6.112 117.833,6.112C119.135,6.112 120.209,6.388 121.185,6.928C122.162,7.467 122.934,8.235 123.476,9.219C124.019,10.203 124.296,11.33 124.296,12.614C124.296,13.118 124.26,13.561 124.2,13.957ZM121.427,11.75C121.415,10.742 121.053,9.939 120.341,9.327C119.63,8.715 118.762,8.415 117.713,8.415C116.76,8.415 115.952,8.715 115.277,9.315C114.602,9.915 114.204,10.73 114.071,11.75L121.427,11.75Z'
            fill='rgb(68,68,68)'
            fillRule='nonzero'
          />
          <path
            id='Vector_9'
            d='M126.009,6.471L126.009,6.124L127.564,6.124L127.564,6.471L126.998,6.471L126.998,8.007L126.576,8.007L126.576,6.471L126.009,6.471Z'
            fill='rgb(68,68,68)'
            fillRule='nonzero'
          />
          <path
            id='Vector_10'
            d='M128.36,6.124L128.806,7.419L129.228,6.124L129.819,6.124L129.819,8.007L129.433,8.007L129.433,6.675L128.963,8.007L128.637,8.007L128.179,6.687L128.179,8.007L127.793,8.007L127.793,6.124L128.36,6.124Z'
            fill='rgb(68,68,68)'
            fillRule='nonzero'
          />
          <g transform='matrix(1,0,0,1,-1.25,2)'>
            <path
              d='M51.96,32.12L50.062,28.675L49.529,28.675L49.529,32.12L47.306,32.12L47.306,22.994L51.037,22.994C51.756,22.994 52.37,23.12 52.877,23.371C53.384,23.622 53.763,23.967 54.014,24.405C54.265,24.842 54.391,25.33 54.391,25.867C54.391,26.474 54.22,27.015 53.878,27.492C53.535,27.969 53.03,28.307 52.363,28.506L54.469,32.12L51.96,32.12ZM49.529,27.102L50.907,27.102C51.314,27.102 51.62,27.002 51.824,26.803C52.027,26.604 52.129,26.322 52.129,25.958C52.129,25.611 52.027,25.338 51.824,25.139C51.62,24.94 51.314,24.84 50.907,24.84L49.529,24.84L49.529,27.102Z'
              fill='rgb(68,68,68)'
              fillRule='nonzero'
            />
          </g>
          <g transform='matrix(1,0,0,1,-1.25,2)'>
            <path
              d='M58.005,24.775L58.005,26.621L60.982,26.621L60.982,28.337L58.005,28.337L58.005,30.339L61.372,30.339L61.372,32.12L55.782,32.12L55.782,22.994L61.372,22.994L61.372,24.775L58.005,24.775Z'
              fill='rgb(68,68,68)'
              fillRule='nonzero'
            />
          </g>
          <g transform='matrix(1,0,0,1,-1.25,2)'>
            <path
              d='M75.373,22.994L72.994,32.12L70.303,32.12L68.847,26.114L67.339,32.12L64.648,32.12L62.334,22.994L64.713,22.994L66.026,29.637L67.651,22.994L70.095,22.994L71.655,29.637L72.981,22.994L75.373,22.994Z'
              fill='rgb(68,68,68)'
              fillRule='nonzero'
            />
          </g>
          <g transform='matrix(1,0,0,1,-1.25,2)'>
            <path
              d='M82.172,30.508L78.766,30.508L78.22,32.12L75.893,32.12L79.195,22.994L81.769,22.994L85.071,32.12L82.718,32.12L82.172,30.508ZM81.6,28.792L80.469,25.451L79.351,28.792L81.6,28.792Z'
              fill='rgb(68,68,68)'
              fillRule='nonzero'
            />
          </g>
          <g transform='matrix(1,0,0,1,-1.25,2)'>
            <path
              d='M90.726,32.12L88.828,28.675L88.295,28.675L88.295,32.12L86.072,32.12L86.072,22.994L89.803,22.994C90.522,22.994 91.136,23.12 91.643,23.371C92.15,23.622 92.529,23.967 92.78,24.405C93.031,24.842 93.157,25.33 93.157,25.867C93.157,26.474 92.986,27.015 92.644,27.492C92.301,27.969 91.796,28.307 91.129,28.506L93.235,32.12L90.726,32.12ZM88.295,27.102L89.673,27.102C90.08,27.102 90.386,27.002 90.59,26.803C90.793,26.604 90.895,26.322 90.895,25.958C90.895,25.611 90.793,25.338 90.59,25.139C90.386,24.94 90.08,24.84 89.673,24.84L88.295,24.84L88.295,27.102Z'
              fill='rgb(68,68,68)'
              fillRule='nonzero'
            />
          </g>
          <g transform='matrix(1,0,0,1,-1.25,2)'>
            <path
              d='M97.967,22.994C98.929,22.994 99.77,23.185 100.489,23.566C101.208,23.947 101.765,24.482 102.16,25.171C102.554,25.86 102.751,26.656 102.751,27.557C102.751,28.45 102.554,29.243 102.16,29.936C101.765,30.629 101.206,31.167 100.483,31.548C99.759,31.929 98.92,32.12 97.967,32.12L94.548,32.12L94.548,22.994L97.967,22.994ZM97.824,30.196C98.665,30.196 99.319,29.966 99.787,29.507C100.255,29.048 100.489,28.398 100.489,27.557C100.489,26.716 100.255,26.062 99.787,25.594C99.319,25.126 98.665,24.892 97.824,24.892L96.771,24.892L96.771,30.196L97.824,30.196Z'
              fill='rgb(68,68,68)'
              fillRule='nonzero'
            />
          </g>
          <g transform='matrix(1,0,0,1,-1.25,2)'>
            <path
              d='M107.301,32.211C106.634,32.211 106.036,32.103 105.507,31.886C104.978,31.669 104.556,31.349 104.24,30.924C103.923,30.499 103.756,29.988 103.739,29.39L106.105,29.39C106.14,29.728 106.257,29.986 106.456,30.164C106.655,30.341 106.915,30.43 107.236,30.43C107.565,30.43 107.825,30.354 108.016,30.202C108.207,30.051 108.302,29.841 108.302,29.572C108.302,29.347 108.226,29.16 108.075,29.013C107.923,28.866 107.737,28.744 107.516,28.649C107.295,28.554 106.98,28.445 106.573,28.324C105.984,28.142 105.503,27.96 105.13,27.778C104.757,27.596 104.437,27.327 104.168,26.972C103.899,26.617 103.765,26.153 103.765,25.581C103.765,24.732 104.073,24.066 104.688,23.585C105.303,23.104 106.105,22.864 107.093,22.864C108.098,22.864 108.909,23.104 109.524,23.585C110.139,24.066 110.469,24.736 110.512,25.594L108.107,25.594C108.09,25.299 107.981,25.067 107.782,24.898C107.583,24.729 107.327,24.645 107.015,24.645C106.746,24.645 106.53,24.716 106.365,24.859C106.2,25.002 106.118,25.208 106.118,25.477C106.118,25.772 106.257,26.001 106.534,26.166C106.811,26.331 107.245,26.508 107.834,26.699C108.423,26.898 108.902,27.089 109.271,27.271C109.639,27.453 109.957,27.717 110.226,28.064C110.495,28.411 110.629,28.857 110.629,29.403C110.629,29.923 110.497,30.395 110.233,30.82C109.968,31.245 109.585,31.583 109.082,31.834C108.579,32.085 107.986,32.211 107.301,32.211Z'
              fill='rgb(68,68,68)'
              fillRule='nonzero'
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id='_Linear2'
          x1='0'
          y1='0'
          x2='1'
          y2='0'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(18.0674,-31.05,31.05,18.0674,8.93407,34.0882)'
        >
          <stop offset='0' stopColor='rgb(11,95,79)' stopOpacity={1} />
          <stop offset='1' stopColor='rgb(2,183,147)' stopOpacity={1} />
        </linearGradient>
      </defs>
    </svg>
  );
}
