import { Box, Button, Card, CardFooter, CardHeader, Center, Text, VStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UploadSuccessIcon } from '~/components/icons';
import { neutralizeEvent } from '~/lib/helpers';

export default function ContactSuccessForm() {
  const navigate = useNavigate();

  const onHandleButton = useCallback(
    (e) => {
      neutralizeEvent(e);
      navigate('/');
    },
    [navigate]
  );

  return (
    <Center bg='secondary.800' flexGrow={1} overflow='hidden' width='100%' py={16}>
      <VStack data-role='contact-container'>
        <Text textStyle='contact-title-success'>Contact us</Text>
        <Card layerStyle='contact-post-submit-card'>
          <CardHeader layerStyle='contact-header-container'>
            <Box align='center' pb='16px'>
              <UploadSuccessIcon />
            </Box>
            <Text textStyle='contact-heading'>We have received your message!</Text>
            <Text textStyle='contact-info'> Our customer service will get back to you soon.</Text>
          </CardHeader>
          <CardFooter layerStyle='contact-body'>
            <Button onClick={onHandleButton} w='100%'>
              Back to homepage
            </Button>
          </CardFooter>
        </Card>
      </VStack>
    </Center>
  );
}
