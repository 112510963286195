import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { PiQuestionThin } from 'react-icons/pi';
import { useConfig } from '~/hooks/useConfig';
import { useTranslations } from '~/hooks/useTranslations';
import { MAX_GIFT_CARD_LIMIT } from '~/lib/constants';
import { formatCurrency, getRegion } from '~/lib/helpers';
import { GIFT_CARD_IMG_WIDTH, GiftCardIcon } from '~/pages/brand/gift_card/gift-card-icon';

export function CardRules({ brand, offer, email, ...vStackProps }) {
  const { giftCardTranslation } = useTranslations();
  const config = useConfig();
  const region = getRegion(config);

  return (
    <VStack {...vStackProps}>
      <GiftCardIcon brand={brand} offer={offer} />

      <HStack alignItems='start' width='100%' maxWidth={`${GIFT_CARD_IMG_WIDTH + 96}px`}>
        <Box w={5} h={5}>
          <PiQuestionThin />
        </Box>
        <Text color='blackAlpha.600' fontSize='xs'>
          {giftCardTranslation.purchaseRules[0]} {formatCurrency(MAX_GIFT_CARD_LIMIT, region)}
          {giftCardTranslation.purchaseRules[1]}
          <b>&nbsp;{giftCardTranslation.payWithCreditCard}</b> {giftCardTranslation.terms}{' '}
          <b style={{ color: 'blackAlpha.600' }}>{email}</b>.
        </Text>
      </HStack>
    </VStack>
  );
}

CardRules.propTypes = {
  brand: PropTypes.object,
  offer: PropTypes.object,
  email: PropTypes.string,
};
