import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(
  alertAnatomy.keys
);

const info = definePartsStyle({
  container: {
    backgroundColor: 'blue.50',
    bg: 'blue.50',
  },
  title: {
    color: 'blue.600',
  },
  description: {
    color: 'blue.600',
  },
  icon: {
    color: 'blue.600',
  },
});
const success = definePartsStyle({
  container: {
    backgroundColor: 'brand.50',
    bg: 'brand.50',
  },
  title: {
    color: 'brand.700',
  },
  description: {
    color: 'brand.700',
  },
  icon: {
    color: 'brand.700',
  },
});
const error = definePartsStyle({
  container: {
    backgroundColor: 'red.50',
    bg: 'red.50',
  },
  title: {
    color: 'red.500',
  },
  description: {
    color: 'red.500',
  },
  icon: {
    color: 'red.500',
  },
});
const warning = definePartsStyle({
  container: {
    backgroundColor: 'orange.50',
    bg: 'orange.50',
  },
  title: {
    color: 'orange.700',
  },
  description: {
    color: 'orange.700',
  },
  icon: {
    color: 'orange.700',
  },
});

export const alertTheme = defineMultiStyleConfig({
  // The parts of the component
  parts: ['container', 'title', 'description', 'icon'],
  // The base styles for each part
  baseStyle: {
    container: {
      borderRadius: '8px',
      px: 4,
      py: 3,
      width: '100%',
      minHeight: 16,
    },
    title: {
      fontWeight: 'medium',
    },
    description: {
      fontWeight: 'normal',
    },
    icon: {
      width: '20px',
      height: '20px',
    },
  },
  // The size styles for each part
  sizes: {},
  // The variant styles for each part
  variants: {
    info,
    success,
    error,
    warning,
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'xs',
    variant: 'info',
    colorScheme: 'blue',
  },
});
