import { Heading, HStack, useBreakpointValue, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const OfferTextBlock = ({ children, headingIcon, headingText }) => {
  const iconSize = useBreakpointValue({ base: 6, sm: 8 });

  return (
    <VStack
      width='100%'
      alignItems='flex-start'
      px={[6, 10]}
      borderRadius='xl'
      py={[6, 8]}
      boxShadow='0px 2px 12px 0px #0000001A'
      data-role='text-block'
      as='article'
      spacing={[4, 7]}
      fontFamily='display'
      fontSize={['14px', '16px']}
    >
      {(headingIcon || headingText) && (
        <HStack
          width='100%'
          spacing={3}
          justifyContent={['center', 'flex-start']}
          color='secondary_color'
        >
          {headingIcon?.(iconSize)}
          <Heading fontSize={['16px', '20px']} fontFamily='display' fontWeight={600} as='h3' p={0}>
            {headingText}
          </Heading>
        </HStack>
      )}
      <VStack alignItems='flex-start' spacing={[4, 7]}>
        {children}
      </VStack>
    </VStack>
  );
};

OfferTextBlock.propTypes = {
  headingIcon: PropTypes.func,
  headingText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
