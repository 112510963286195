import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import * as Yup from 'yup';
import { useAccountAction } from '~/hooks/account/useAccountAction';
import { useConfig } from '~/hooks/useConfig';
import { useTranslations } from '~/hooks/useTranslations';
import { useValidationSchema } from '~/hooks/useValidationSchema';
import { handleError } from '~/lib/errors';
import { goUrl, neutralizeEvent } from '~/lib/helpers';
import { logEvent } from '~/lib/logEvent';
import { message, TOAST_STATUS } from '~/lib/message';
import { sha256Hash } from '~/lib/sha256Hash.js';
import { ErrorRow } from '../common/error-row';
import { ForgotPasswordModalContent } from './forgot-password-modal-content';

const initialValues = {
  email: '',
  password: '',
};

export function SignInModal({ isOpen, onClose, openSignUp, onEmailVerification }) {
  const { genericTranslation } = useTranslations();
  const { signIn } = useAccountAction();
  const {
    account: { sign_up_url },
  } = useConfig();
  const { emailValidation } = useValidationSchema();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    email: emailValidation,
    password: Yup.string().required(genericTranslation.validation.passwordRequired),
  });

  const goToSignUp = useCallback(
    (e) => {
      neutralizeEvent(e);
      if (sign_up_url) {
        goUrl(sign_up_url);
      } else {
        onClose();
        openSignUp();
      }
    },
    [onClose, openSignUp, sign_up_url]
  );

  const onSubmit = async ({ email, password }, { resetForm }) => {
    try {
      await signIn(email, password);

      resetForm();
      onClose();

      message({
        title: genericTranslation.signIn.successTitle,
        description: genericTranslation.signIn.successDescription,
        status: TOAST_STATUS.SUCCESS,
        duration: 4000,
        isClosable: true,
      });
      const hashedEmail = await sha256Hash(email);
      logEvent('login', { action: 'sign_in', email: hashedEmail });
    } catch (error) {
      const errBody = await error.response.clone().json();
      if (errBody.status === 400 && /user is not confirmed/i.test(errBody.message)) {
        onEmailVerification(values.email);
      } else {
        handleError(error, 'Cannot sign up');
      }
      resetForm();
      onClose();
    }
  };

  const { handleSubmit, handleReset, values, handleBlur, handleChange, errors } = useFormik({
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const handleClose = useCallback(() => {
    onClose();
    setShowPassword(false);
    setShowResetPassword(false);
    handleReset();
  }, [handleReset, onClose, setShowPassword]);

  const isDisabled = Object.keys(errors).length > 0;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered blockScrollOnMount>
      <ModalOverlay />
      {showResetPassword ? (
        <ForgotPasswordModalContent />
      ) : (
        <ModalContent as='form' onSubmit={handleSubmit}>
          <ModalHeader mb={0}>{genericTranslation.welcome}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={0}>
            <Text color='secondary.primary' mb={4} textAlign='center'>
              {genericTranslation.signIn.instructions}
            </Text>
            <Stack direction='column' spacing={4}>
              <VStack>
                <FormControl>
                  <FormLabel>{genericTranslation.email}</FormLabel>
                  <Input
                    name='email'
                    layerStyle='sign-in-field'
                    value={values.email}
                    placeholder={genericTranslation.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
                <ErrorRow isPresent={Boolean(errors.email)}>{errors.email}</ErrorRow>
              </VStack>

              <VStack>
                <FormControl>
                  <Box w='100%'>
                    <HStack w='100%' justify='space-between' mb={2}>
                      <FormLabel mb={0}>{genericTranslation.password}</FormLabel>
                    </HStack>
                  </Box>
                  <InputGroup>
                    <Input
                      name='password'
                      layerStyle='sign-in-field'
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      placeholder={genericTranslation.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <InputRightElement height='100%'>
                      <Button
                        onClick={() => setShowPassword((current) => !current)}
                        color='blackAlpha.500'
                        variant='unstyled'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        mr={1}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <ErrorRow isPresent={Boolean(errors.password)}>{errors.password}</ErrorRow>
              </VStack>
            </Stack>
          </ModalBody>

          <ModalFooter display='flex' flexDir='column'>
            <Button
              width='100%'
              isDisabled={isDisabled}
              onClick={handleSubmit}
              type='submit'
              mb={3}
            >
              {genericTranslation.signIn.cta}
            </Button>
            <Text textAlign='center'>
              {`${genericTranslation.signUp.dontHaveAccount} `}
              <Button
                variant='link'
                mt={2}
                display='inline-block'
                fontWeight='normal'
                onClick={goToSignUp}
              >
                {genericTranslation.signUp.cta}
              </Button>
            </Text>
            <Button
              variant='link'
              mt={2}
              fontWeight='normal'
              onClick={() => {
                setShowResetPassword(true);
              }}
            >
              {genericTranslation.resetPassword.cta}
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}

SignInModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openSignUp: PropTypes.func.isRequired,
  onEmailVerification: PropTypes.func.isRequired,
};
