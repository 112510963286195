import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import { BRANDS_ROUTE } from '~/lib/constants.js';
import imgUrl from '~/lib/helpers';
import BrandToken from '~/pages/landing/brand-token';
import Carousel from '~/components/carousel/carousel';

// ---- 01 - intro

export const OfferButton = memo(function OfferButton({ responsive }) {
  return (
    <Link to={BRANDS_ROUTE}>
      <Button variant='landingPageOffer'>{responsive.offerButtonLabel}</Button>
    </Link>
  );
});

OfferButton.propTypes = { responsive: PropTypes.object };

export const LandingPageOneImageBox = () => (
  <Box
    display='block'
    data-role='lp-art-1'
    background={imgUrl('/img/lp/shutterstock_2140128365.jpg')}
    layerStyle='landing-page-one-image-box'
  />
);

export const HorizontalBrandRow = ({ imgBrands, responsive }) => {
  const responsiveSliders = useBreakpointValue({
    base: { slidesToShow: 0 },
    sm: { slidesToShow: 0 },
    md: { slidesToShow: 5 },
    lg: { slidesToShow: 7 },
    xl: { slidesToShow: 9 },
  });

  const settings = {
    dots: false,
    slidesToShow: responsiveSliders.slidesToShow,
  };

  const brandGrid = imgBrands.map((brand) => (
    <HStack key={brand.brand_uid}>
      <Center>
        <BrandToken brand={brand} />
      </Center>
    </HStack>
  ));

  return responsive.showFooter ? (
    <Box layerStyle='landing-brand-row-grid'>
      <Carousel {...settings}>{brandGrid}</Carousel>
    </Box>
  ) : null;
};

HorizontalBrandRow.propTypes = {
  imgBrands: PropTypes.array,
  responsive: PropTypes.object,
};

export const IntroText = ({ responsive, landingTranslation }) => (
  <Box layerStyle='landing-intro-text' data-role='intro-text'>
    <Heading variant='lp1'>{landingTranslation.brand_container.heading_text}</Heading>
    <Text textStyle='landing-intro'>{landingTranslation.brand_container.subheading_text}</Text>
    {!responsive.showOfferButton ? (
      <Box pb='40px'>
        <OfferButton responsive={responsive} />
      </Box>
    ) : null}
  </Box>
);

IntroText.propTypes = {
  landingTranslation: PropTypes.object,
  responsive: PropTypes.object,
};

// ---- 02 - getting started
export function GettingStartedColumn({ src, title, children }) {
  return (
    <Box w={['100%', '100%', '350px']}>
      <VStack>
        <Image src={src} height={200} width={216} />
        <Heading variant='landing-gs'>{title}</Heading>
        {children}
      </VStack>
    </Box>
  );
}

GettingStartedColumn.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  title: PropTypes.string,
};
