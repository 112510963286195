import { Text } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';

export const ErrorRow = ({ isPresent = false, children, ...textProps }) => {
  return isPresent ? (
    <Text color='red' fontSize='xs' ml={2.5} width='100%' {...textProps}>
      {children}
    </Text>
  ) : null;
};

ErrorRow.propTypes = ErrorRow.propTypes = {
  isPresent: PropTypes.bool,
  children: PropTypes.node,
};
