import { res } from '~/lib/helpers';
import {
  BRAND_BANNER_HEIGHT,
  BRAND_IMAGE_RADIUS,
  BRAND_PADDING,
  BRAND_PAGE_BANNER_PX,
  BRAND_PAGE_LOGO_HEIGHT,
  BRAND_PAGE_LOGO_PX,
  IMAGE_COVER,
} from '~/theme/constants';

export const brandTheme = {
  layerStyles: {
    brand: {
      mx: [0, 0.5, 1],
      my: [0, 0.5, 1],
      position: 'relative',
      minWidth: ['100%', '169px', '223px', '223px', '223px'],
    },
    'brand-image': {
      ...IMAGE_COVER,
      width: '100%',
      height: BRAND_BANNER_HEIGHT,
    },
    'brand-page-banner': {
      w: '100%',
      h: BRAND_PAGE_BANNER_PX,
      ...IMAGE_COVER,
      position: 'relative',
      borderTopLeftRadius: res({ base: 0, lg: 20 }, true),
      borderTopRightRadius: res({ base: 0, lg: 20 }, true),
    },

    'brand-page-logo': {
      ...IMAGE_COVER,
      w: BRAND_PAGE_LOGO_PX,
      h: BRAND_PAGE_LOGO_PX,
      border: '3px solid white',
      shadow: 'md',
    },
    'brand-page-logo-wrapper': {
      position: 'relative',
      w: '100%',
      h: '1px',
      overflow: 'show',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      mb: `${BRAND_PAGE_LOGO_HEIGHT / 2 + 13}px`, // 10px padding, 3px border
    },
    'brand-text': {
      p: BRAND_PADDING,
      borderBottomLeftRadius: BRAND_IMAGE_RADIUS,
      borderBottomRightRadius: BRAND_IMAGE_RADIUS,
      border: '1px solid',
      borderColor: 'blackAlpha.300',
    },
  },
};
