import { SimpleGrid, Text } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslations } from '~/hooks/useTranslations';
import { BrandOffer } from '~/pages/brand/brand-offer';

export function OfferList({ offers, brand }) {
  const { brandsTranslation } = useTranslations();
  const [openOfferId, setOpenOfferId] = useState(null);

  let visibleOffers = offers;
  if (openOfferId) {
    const currentOffer = offers.find((o) => o.offer_uid === openOfferId);
    if (currentOffer) {
      visibleOffers = [currentOffer];
    }
  }

  switch (visibleOffers.length) {
    case 0:
      return <Text>{brandsTranslation.noOffers}</Text>;
    case 1: {
      return (
        <BrandOffer
          offer={visibleOffers[0]}
          brand={brand}
          brandHasMultiOffers={offers.length > 1}
          openOfferId={openOfferId}
          selectOpenOfferId={setOpenOfferId}
        />
      );
    }
    default:
      return (
        <SimpleGrid data-testid='offers_list' columns={[1, 1, 2, 2]} spacing='20px'>
          {visibleOffers.map((offer) => (
            <BrandOffer
              key={offer.offer_uid}
              offer={offer}
              brand={brand}
              brandHasMultiOffers={offers.length > 1}
              openOfferId={openOfferId}
              selectOpenOfferId={setOpenOfferId}
            />
          ))}
        </SimpleGrid>
      );
  }
}

OfferList.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.object).isRequired,
  brand: PropTypes.object,
};
