import { useContext } from 'react';
import { SearchContext } from '~/lib/state/contexts/SearchProvider';

export const useSearch = () => {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useSearch must be used within SearchProvider');
  }

  return context;
};
