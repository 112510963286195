import { useEffect } from 'react';
import { useLocation } from 'react-router';

const RESET_PASSWORD_ROUTE = '/reset-password';

/**
 *
 * @param {func} setSettingsModalIsOpen Function for opening and closing the settings modal
 * @description A hook which listens for the route users are sent to when they want to reset their password and
 * conditionally shows the change password modal.
 */
export const useResetPassword = ({ resetPasswordModalIsOpen, setResetPasswordModalIsOpen }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === RESET_PASSWORD_ROUTE && !resetPasswordModalIsOpen) {
      setResetPasswordModalIsOpen(true);
    }
  }, [pathname, setResetPasswordModalIsOpen, resetPasswordModalIsOpen]);
};
