import { Box } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useBgGradient } from '~/hooks/useBgGradient';
import { HorizontalBrandRow } from '~/pages/landing/helpers';

export function LandingPage03Brands({ imgBrands, responsive }) {
  const bgGradient = useBgGradient();

  return (
    <Box bgGradient={bgGradient} pb={[0, 8]}>
      <HorizontalBrandRow responsive={responsive} imgBrands={imgBrands} />
    </Box>
  );
}

LandingPage03Brands.propTypes = {
  imgBrands: PropTypes.array,
  responsive: PropTypes.object,
};
