/**
 * @description An unextended subclass of Error.
 * Meant purely for differentiating between instances of it and its superclass.
 */
export class SilentError extends Error {
  constructor() {
    super();
  }
}

/**
 *
 * @param {*} error
 * @returns {boolean}
 * @description Returns true if the argument is an instance of {@link SilentError}.
 */
export const isSilentError = (error) => error instanceof SilentError;

/**
 * @description No-op function. Meant for gracefully swallowing errors within {@link handleError}.
 */
export const handleSilentError = () => {
  // swallow error
};
