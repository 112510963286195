let toast = null;

export function setToast(t) {
  toast = t;
}

export const TOAST_STATUS = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const TOAST_DURATION = {
  NORMAL: 4000,
  LONG: 10000,
};

const DEFAULT_PARAMS = {
  status: TOAST_STATUS.INFO,
  variant: TOAST_STATUS.INFO,
  duration: 4000,
  isClosable: true,
};

export function message(toastData, textAlternative) {
  if (typeof toastData === 'string') {
    return message({ description: toastData, title: 'message' });
  }

  const msg = textAlternative ?? `${toastData.title} ${toastData.description}`;

  if (toast) {
    toast({
      ...DEFAULT_PARAMS,
      ...toastData,
      // if variant isn't defined, use status for variant
      variant: toastData.variant ?? toastData.status,
    });
    if (typeof console === 'undefined') return;
    console.info('...message: ', toastData.title, toastData.description, toastData);
  } else {
    if (typeof console === 'undefined') return;
    switch (toastData.status) {
      case TOAST_STATUS.INFO:
        console.info(msg);
        break;

      case TOAST_STATUS.ERROR:
        console.error(msg);
        break;

      default:
        console.log(msg);
    }
  }
}
