import { Button, Flex, VStack } from '@chakra-ui/react';
import { useTranslations } from '~/hooks/useTranslations';

export const BuyPrompt = () => {
  const { brandsTranslation } = useTranslations();

  return (
    <Flex
      position='absolute'
      left={0}
      top={0}
      bottom={0}
      right={0}
      justifyContent='center'
      alignItems='center'
    >
      <VStack>
        <Button variant='baseStyle'>{brandsTranslation.redeemPrompt}</Button>
      </VStack>
    </Flex>
  );
};
