import { useBreakpointValue } from '@chakra-ui/react';

const useIsNarrowScreen = () => {
  return useBreakpointValue(
    {
      base: true,
      sm: true,
      md: false,
      lg: false,
    },
    {
      fallback: 'md',
    }
  );
};

export default useIsNarrowScreen;
