import { Button, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useConfig } from '~/hooks/useConfig';
import { useTranslationHelpers } from '~/hooks/useTranslationHelpers';
import { getBrandPath } from '~/lib/helpers';

export function RelatedOffer({ brand }) {
  const { offerAmountText } = useTranslationHelpers();
  const {
    publisher: { region },
  } = useConfig();
  const offerText = offerAmountText({
    offerAmount: brand?.max_customer_offer,
    region,
    rewardType: brand?.reward_type,
  });

  if (brand?.brand_uid == null) {
    return null;
  }

  return (
    <HStack
      border='1px solid'
      borderColor='secondary.200'
      borderRadius='xl'
      overflow='hidden'
      h={{ base: '7rem', md: '10rem' }}
      width='100%'
      gap={0}
    >
      <Image
        src={brand.logo_img_url}
        borderRight='1px solid'
        borderRightColor='secondary.200'
        height='100%'
        width={{ base: '7rem', md: '10rem' }}
        objectFit='cover'
        alt={`${brand.name} Logo`}
      />
      <VStack
        p={4}
        height='100%'
        justify='space-between'
        alignItems='start'
        gap={0}
        color='neutral.primary'
      >
        <Heading fontSize='xs' fontWeight={600} textTransform='uppercase' fontFamily='display'>
          {brand.name}
        </Heading>
        <Text noOfLines={2} fontSize='md' fontFamily='display' fontWeight={500}>
          {offerText}
        </Text>
        <Link to={getBrandPath(brand)}>
          <Button variant='categoryCashBack'>Cashback</Button>
        </Link>
      </VStack>
    </HStack>
  );
}

RelatedOffer.propTypes = {
  brand: PropTypes.object,
};
