import {
  Box,
  Button,
  CloseButton,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { getDate } from 'date-fns';
import * as PropTypes from 'prop-types';
import { memo } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslations } from '~/hooks/useTranslations';

const TransactionSearch = memo(function TransSearchBase({
  onReset,
  searchFieldPhrase,
  startDate,
  endDate,
  onSearchFieldChange,
  onDateChange,
  onResetDate,
  ...props
}) {
  const { genericTranslation: t, accountTranslation: a } = useTranslations();

  return (
    <Box {...props}>
      <Stack direction={['column', 'column', 'column', 'row']} spacing={4}>
        <InputGroup variant='search' width='100%'>
          <InputLeftAddon
            backgroundColor='white'
            borderTopLeftRadius='20px'
            borderBottomLeftRadius='20px'
          >
            <img alt='search icon' src='/icons/text-search.svg' />
          </InputLeftAddon>
          <Input
            name='search'
            placeholder={a.transaction.searchForTransactions}
            onChange={onSearchFieldChange}
            value={searchFieldPhrase ?? ''}
            width='100%'
          />
        </InputGroup>
        <HStack spacing={2} width='100%' align='center'>
          <Box py={2}>
            <Text whiteSpace='nowrap'>{t.date}</Text>
          </Box>
          <DatePicker
            locale='en'
            selectsRange
            onChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
            withPortal
            maxDate={new Date()}
            utcOffset={7}
            dayClassName={() => 'datepicker-day-cell'}
            renderDayContents={(day, date) => (
              <HStack w='100%' h='100%' justify='center'>
                <Text size='large'>{getDate(date)}</Text>
              </HStack>
            )}
            placeholder={t.selectDateRange}
            customInput={<Input width='100%' textAlign='center' fontSize='sm' />}
          />
          {startDate || endDate ? <CloseButton title={t.clearDates} onClick={onResetDate} /> : null}
          <Button onClick={onReset} variant='outline-thin' ml='auto'>
            {t.clear}
          </Button>
        </HStack>
      </Stack>
    </Box>
  );
});

export default TransactionSearch;

TransactionSearch.propTypes = {
  onReset: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  searchFieldPhrase: PropTypes.string,
  onSearchFieldChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onResetDate: PropTypes.func,
};
