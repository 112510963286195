import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import usePublisherName from '~/hooks/usePublisherName';
import { SearchList } from '~/pages/search/search-list';

export function SearchPage() {
  const { publisherName } = usePublisherName();
  return (
    <Fragment>
      <Helmet>
        <title>Search | {publisherName}</title>
      </Helmet>
      <SearchList />
    </Fragment>
  );
}
