export const brandsTranslation = {
  categories: {
    root: 'Categories',
    all: 'All Categories',
    more: 'More Categories',
  },
  noBrands: 'No brands found',

  searchForBrands: 'Search for brands',
  searchForBrandsIn: (category) => `Search for brands in ${category}`,

  searchResults: 'Search Results',
  searchResultsFor: (searchPhrase) => `Search Results for "${searchPhrase}"`,
  searchResultsIn: (category) => `Search Results in ${category}`,
  searchResultsForIn: (searchPhrase, category) =>
    `Search Results for "${searchPhrase}" in ${category}`,
  CAROUSEL_IMAGES: [
    {
      image_path: '/img/carousel-images/food&drink-category.png',
      title: 'Get the most out of your dining',
      description: 'Earn Rewards, Dine More, Save Money',
      key: 'Food & Drink',
      category_id: '8',
    },
    {
      image_path: '/img/carousel-images/beauty-category.png',
      title: 'Explore a variety of beauty offers',
      description: 'Earn rewards while practicing your favorite self care routine',
      key: 'Health & Beauty',
      category_id: '11',
    },
    {
      image_path: '/img/carousel-images/fashion-category.png',
      title: 'Dress Your Best With Leaders in Fashion',
      description: 'Save big with offers on fashion to elevate your style',
      key: 'Fashion',
      category_id: '6',
    },
  ],
  details: {
    location: 'What do I do now?',
    cashbackRates: 'Cashback Rates',
    details: 'Details',
    cashbackAutomatic: 'Automatic Cashback',
    'terms&cond': 'Terms and Conditions',
    cashbackRatesDesc: 'Unlock this exclusive chance to receive up to ',
  },
  buyPrompt: 'Join for cash back offers!',
  redeemPrompt: 'Redeem offer',
  merchantNav: {
    activated: 'Activated!',
    preOfferText: {
      noCommissionGroups: '',
      hasCommissionGroups: 'Up to',
    },
  },
  offerDetailsBtn: 'Offer Details',
  hideDetailsBtn: 'Hide Details',
  preOfferText: {
    noCommissionGroups: 'Get',
    hasCommissionGroups: 'Get up to',
  },
  onText: 'on',
  onAllItemsExcludingText: 'on all items excluding',
  andText: 'and',
  noLocationTxt: 'No Location',
  noOffers: 'No Offers',
  offerListHeading: {
    single: 'Cashback & Offer',
    plural: 'Cashback & Offers',
  },
  relatedOffers: 'Related Offers',
  offerType: {
    CARD_LINKED: {
      brandOfferCtaUnauthenticated: 'Sign up to activate',
      brandOfferCtaAuthenticated: {
        inStoreOnly: 'Earn cash back by using any of your linked cards at this store.',
        online: 'Shop now',
      },
      brandOfferCtaNoCardsLinked: 'Link a card',
      automaticCashback: [
        "Remember to shop with a registered account to make sure you don't miss out on your free cash!",
        'Remember',
      ],
      instructions: {
        IN_STORE_ONLINE: {
          unauthenticated: [
            "To earn cashback, please create an account and link a card in 'My Wallet'.",
          ],
          authenticated: [
            "To earn cashback, make sure to complete your purchase at the merchant's website or any of their physical locations using your linked card.",
            "Once your purchase is confirmed, we'll automatically credit the cashback to your account.",
          ],
          noCardsLinked: [
            "To earn cashback, make sure to complete your purchase at the merchant's website or any of their physical locations using your linked card.",
            "Once your purchase is confirmed, we'll automatically credit the cashback to your account.",
          ],
        },
        IN_STORE_ONLY: {
          unauthenticated: [
            "To earn cashback, please create an account and link a card in 'My Wallet'.",
          ],
          authenticated: [
            "To earn cashback, make sure to complete your purchase at one of the merchant's physical locations using your linked card.",
            "Once your purchase is confirmed, we'll automatically credit the cashback to your account.",
          ],
          noCardsLinked: [
            "To earn cashback, please link a card and complete your purchase at one of the merchant's physical locations using that linked card.",
            "Once your purchase is confirmed, we'll automatically credit the cashback to your account.",
          ],
          disclaimer: [
            'Please note that online purchases at this brand do not qualify for cashback.',
          ],
        },
        ONLINE_ONLY: {
          unauthenticated: [
            "To earn cashback, please create an account and link a card in 'My Wallet'.",
          ],
          authenticated: [
            "To earn cashback, make sure to complete your purchase on the merchant's website using your linked card.",
            "Once your purchase is confirmed, we'll automatically credit the cashback to your account.",
            'Please note that in-store purchases at this brand do not qualify for cashback.',
          ],
          noCardsLinked: [
            "To earn cashback, please link a card and complete your purchase on the merchant's website using that linked card.",
            "Once your purchase is confirmed, we'll automatically credit the cashback to your account.",
            'Please note that in-store purchases at this brand do not qualify for cashback.',
          ],
        },
      },
    },
    AFFILIATE: {
      brandOfferCtaUnauthenticated: 'Sign up to activate',
      brandOfferCtaAuthenticated: 'Shop now',
      brandOfferCtaNoCardsLinked: 'Shop now',
      automaticCashback: [
        'When you make a purchase by clicking on this offer, we are automatically notified and will add cashback to your account once the purchase is confirmed. This may take 1-5 days.',
        'Automatic Cashback',
      ],
      instructions: {
        IN_STORE_ONLINE: {
          unauthenticated: ['To earn cashback, please create an account and sign in.'],
          authenticated: [
            'To get cashback, you must click on the "Shop now" link above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
          noCardsLinked: [
            'To get cashback, you must click on the "Shop now" link above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
        },
        IN_STORE_ONLY: {
          unauthenticated: ['To earn cashback, please create an account and sign in.'],
          authenticated: [
            'To get cashback, you must click on the "Shop now" link above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
          noCardsLinked: [
            'To get cashback, you must click on the "Shop now" link above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
        },
        ONLINE_ONLY: {
          unauthenticated: ['To earn cashback, please create an account and sign in.'],
          authenticated: [
            'To get cashback, you must click on the "Shop now" link above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
          noCardsLinked: [
            'To get cashback, you must click on the "Shop now" link above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
        },
      },
    },
    GIFT_CARD: {
      brandOfferCtaUnauthenticated: 'Sign up to activate',
      brandOfferCtaAuthenticated: 'Instant Cashback',
      brandOfferCtaNoCardsLinked: 'Link a card',
      automaticCashback: [
        'Complete a purchase of a gift card to receive cashback.',
        'Instant Cashback',
      ],
      instructions: {
        IN_STORE_ONLINE: {
          unauthenticated: ['To earn cashback, please create an account and sign in.'],
          authenticated: [
            'Once you receive your gift card via email, you can make purchases using the gift card both on the merchant’s online website and any physical location.',
          ],
          noCardsLinked: [
            'Once you receive your gift card via email, you can make purchases using the gift card both on the merchant’s online website and any physical location.',
          ],
        },
        IN_STORE_ONLY: {
          unauthenticated: ['To earn cashback, please create an account and sign in.'],
          authenticated: [
            'Once you receive your gift card via email, you can make purchases using the gift card at any physical location.',
          ],
          noCardsLinked: [
            'Once you receive your gift card via email, you can make purchases using the gift card at any physical location.',
          ],
        },
        ONLINE_ONLY: {
          unauthenticated: ['To earn cashback, please create an account and sign in.'],
          authenticated: [
            'Once you receive your gift card via email, you can make purchases using the gift card at the merchant’s online website.',
          ],
          noCardsLinked: [
            'Once you receive your gift card via email, you can make purchases using the gift card at the merchant’s online website.',
          ],
        },
      },
    },
    VOUCHER_CODE: {
      brandOfferCtaUnauthenticated: 'Sign up to activate',
      brandOfferCtaAuthenticated: 'Click for code',
      brandOfferCtaNoCardsLinked: 'Link a card',
      automaticCashback: ['---', 'Voucher'],
      instructions: {
        IN_STORE_ONLINE: {
          unauthenticated: ['To earn cashback, please create an account and sign in.'],
          authenticated: [
            'To get cashback, you must click on the “Click for code" button above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
          noCardsLinked: [
            'To get cashback, you must click on the “Click for code" button above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
        },
        IN_STORE_ONLY: {
          unauthenticated: ['To earn cashback, please create an account and sign in.'],
          authenticated: [
            'To get cashback, you must click on the “Click for code" button above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
          noCardsLinked: [
            'To get cashback, you must click on the “Click for code" button above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
        },
        ONLINE_ONLY: {
          unauthenticated: ['To earn cashback, please create an account and sign in.'],
          authenticated: [
            'To get cashback, you must click on the “Click for code" button above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
          noCardsLinked: [
            'To get cashback, you must click on the “Click for code" button above and complete your purchase on their website.',
            'We’ll then automatically be notified of your purchase and add the cashback to your account.',
            'Note that you won’t earn cashback at this brand when you purchase offline, at one of their locations.',
          ],
        },
      },
    },
  },
  rewardTypeSymbol: {
    CASHBACK_DOLLAR: ' Cashback',
    CASHBACK_PERCENTAGE: ' Cashback',
    DISCOUNT_DOLLAR: ' off',
    DISCOUNT_PERCENTAGE: ' off',
  },
  location: {
    IN_STORE_ONLINE: 'In-Store & Online',
    IN_STORE_ONLY: 'In-Store',
    ONLINE_ONLY: 'Online',
  },
  filters: {
    ONLINE: 'Online',
    IN_STORE: 'In Store',
    PAYMENT_LINKED: 'Payment Linked',
    GIFT_CARD: 'Gift Card',
    VIEW_ALL: 'View All',
    BUTTON_PREFIX: 'Filter By',
  },
};
