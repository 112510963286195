export const giftCardTranslation = {
  purchaseRules: [
    'You can only purchase a max of',
    ' in gift card value, or a max of 5 gift cards per week. Your digital gift card will be delivered to you via email immediately after purchase. Cashback is typically added to your account balance in 2-3 days. By clicking',
  ],
  terms:
    'you acknowledge and accept all terms and conditions. Your digital card will be sent to your account address,',
  payWithCreditCard: 'Pay with Credit Card,',
  termsCheckboxText: 'Check the box to accept all terms & conditions',
  inputLabel: (min, max) => `Enter a value between ${min} and ${max}`,
  other: 'Other',
  returnPolicy: 'There are no returns or refunds on any gift cards.',
  authorizeNet: 'Continuing to Authorize.net to Payment Page',
  validation: {
    belowOfferMin: (offerMinValue) => `The minimum card value for this offer is ${offerMinValue}`,
    exceedsOfferMax: (offerMaxValue) => `The maximum card value for this offer is ${offerMaxValue}`,
    exceededPurchasesError: 'You have reached the maximum allowed weekly gift card purchases',
    exceedsWeeklyValue: (giftCardBalance) =>
      `You may only purchase an additional ${giftCardBalance} of gift cards this week`,
    terms: 'Please accept the terms and conditions',
  },
};
