import { Icon } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';

const DEFAULT_WIDTH = 94;
const DEFAULT_HEIGHT = 105;

export const SettingsGearsIcon = ({
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
  size,
  ...rest
}) => {
  return (
    <Icon width={size ?? width} height={size ?? height} viewBox='0 0 94 105' fill='none' {...rest}>
      <path
        d='M25.1402 59.3596C20.5325 57.9878 16.431 55.4409 13.1833 52.0662C14.3949 50.6303 15.125 48.775 15.125 46.7492C15.125 42.1929 11.4314 38.4992 6.875 38.4992C6.59938 38.4992 6.32691 38.5128 6.05824 38.5392C5.69221 36.7497 5.5 34.897 5.5 32.9992C5.5 30.1247 5.94106 27.3531 6.75921 24.7484C6.79774 24.749 6.83633 24.7492 6.875 24.7492C11.4314 24.7492 15.125 21.0556 15.125 16.4992C15.125 15.1912 14.8206 13.9544 14.2788 12.8555C17.4591 9.8984 21.3407 7.68537 25.6466 6.49341C27.011 9.16785 29.7917 10.9993 33 10.9993C36.2083 10.9993 38.9889 9.16785 40.3534 6.49341C44.6593 7.68537 48.5409 9.8984 51.7212 12.8555C51.1794 13.9544 50.875 15.1912 50.875 16.4992C50.875 21.0556 54.5687 24.7492 59.125 24.7492C59.1636 24.7492 59.2023 24.749 59.2408 24.7484C60.0589 27.3531 60.5 30.1247 60.5 32.9992C60.5 34.897 60.3078 36.7497 59.9418 38.5392C59.6731 38.5128 59.4007 38.4992 59.125 38.4992C54.5687 38.4992 50.875 42.1929 50.875 46.7492C50.875 48.775 51.6051 50.6303 52.8166 52.0662C49.569 55.4409 45.4675 57.9878 40.8598 59.3596C39.7963 56.0331 36.6795 53.6242 33 53.6242C29.3205 53.6242 26.2036 56.0331 25.1402 59.3596Z'
        fill='currentColor'
      />
      <path
        d='M33 42.6243C38.3158 42.6243 42.625 38.315 42.625 32.9993C42.625 27.6835 38.3158 23.3743 33 23.3743C27.6842 23.3743 23.375 27.6835 23.375 32.9993C23.375 38.315 27.6842 42.6243 33 42.6243Z'
        fill='currentColor'
        stroke='white'
        strokeWidth='5.5'
        strokeLinejoin='round'
      />
      <path
        d='M64.2838 85.1703C60.9327 84.1726 57.9498 82.3203 55.5879 79.866C56.469 78.8217 57 77.4724 57 75.9991C57 72.6854 54.3137 69.9991 51 69.9991C50.7995 69.9991 50.6014 70.009 50.406 70.0282C50.1398 68.7267 50 67.3793 50 65.9991C50 63.9085 50.3208 61.8928 50.9158 59.9985C50.9438 59.9989 50.9719 59.9991 51 59.9991C54.3137 59.9991 57 57.3128 57 53.9991C57 53.0478 56.7786 52.1483 56.3846 51.3491C58.6975 49.1985 61.5205 47.589 64.6521 46.7222C65.6444 48.6672 67.6667 49.9992 70 49.9992C72.3333 49.9992 74.3556 48.6672 75.3479 46.7222C78.4795 47.589 81.3025 49.1985 83.6154 51.3491C83.2214 52.1483 83 53.0478 83 53.9991C83 57.3128 85.6863 59.9991 89 59.9991C89.0281 59.9991 89.0562 59.9989 89.0842 59.9985C89.6792 61.8928 90 63.9085 90 65.9991C90 67.3793 89.8602 68.7267 89.594 70.0282C89.3986 70.009 89.2005 69.9991 89 69.9991C85.6863 69.9991 83 72.6854 83 75.9991C83 77.4724 83.531 78.8217 84.4121 79.866C82.0502 82.3203 79.0673 84.1726 75.7162 85.1703C74.9428 82.751 72.676 80.9991 70 80.9991C67.324 80.9991 65.0572 82.751 64.2838 85.1703Z'
        fill='currentColor'
      />
      <path
        d='M70 72.999C73.866 72.999 77 69.865 77 65.999C77 62.133 73.866 58.999 70 58.999C66.134 58.999 63 62.133 63 65.999C63 69.865 66.134 72.999 70 72.999Z'
        fill='currentColor'
        stroke='white'
        strokeWidth='4'
        strokeLinejoin='round'
      />
      <path
        d='M22.1391 100.473C18.9975 99.5378 16.201 97.8013 13.9867 95.5003C14.8127 94.5213 15.3105 93.2563 15.3105 91.8751C15.3105 88.7685 12.7922 86.2501 9.68555 86.2501C9.49762 86.2501 9.31185 86.2594 9.12866 86.2774C8.8791 85.0573 8.74805 83.7941 8.74805 82.5001C8.74805 80.5402 9.04877 78.6505 9.6066 76.8746C9.63287 76.8749 9.65918 76.8751 9.68555 76.8751C12.7922 76.8751 15.3105 74.3567 15.3105 71.2501C15.3105 70.3583 15.103 69.515 14.7336 68.7658C16.902 66.7496 19.5485 65.2407 22.4844 64.428C23.4147 66.2515 25.3106 67.5002 27.498 67.5002C29.6855 67.5002 31.5814 66.2515 32.5117 64.428C35.4476 65.2407 38.0941 66.7496 40.2625 68.7658C39.8931 69.515 39.6855 70.3583 39.6855 71.2501C39.6855 74.3567 42.204 76.8751 45.3105 76.8751C45.3369 76.8751 45.3632 76.8749 45.3895 76.8746C45.9473 78.6505 46.248 80.5402 46.248 82.5001C46.248 83.7941 46.117 85.0573 45.8674 86.2774C45.6842 86.2594 45.4985 86.2501 45.3105 86.2501C42.204 86.2501 39.6855 88.7685 39.6855 91.8751C39.6855 93.2563 40.1834 94.5213 41.0094 95.5003C38.7951 97.8013 35.9986 99.5378 32.857 100.473C32.1319 98.205 30.0068 96.5626 27.498 96.5626C24.9893 96.5626 22.8642 98.205 22.1391 100.473Z'
        fill='currentColor'
      />
      <path
        d='M27.498 89.0632C31.1224 89.0632 34.0605 86.1251 34.0605 82.5007C34.0605 78.8764 31.1224 75.9382 27.498 75.9382C23.8737 75.9382 20.9355 78.8764 20.9355 82.5007C20.9355 86.1251 23.8737 89.0632 27.498 89.0632Z'
        fill='currentColor'
        stroke='white'
        strokeWidth='3.75'
        strokeLinejoin='round'
      />
    </Icon>
  );
};

SettingsGearsIcon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
