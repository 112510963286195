import {
  Box,
  Button,
  CircularProgress,
  Heading,
  HStack,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { chunk } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrandFilter } from '~/components/brand-filter/brand-filter';
import { useBrandFilter } from '~/hooks/useBrandFilter';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import usePublisherName from '~/hooks/usePublisherName';
import { BRANDS_API_URL, SORT_BY, SORT_DIRECTION } from '~/lib/constants';
import { handleError } from '~/lib/errors';
import { Brand } from '~/pages/brands/brand';

const BRANDS_PAGE_SIZE = 16;
const BRANDS_BASE_QUERY = {
  size: BRANDS_PAGE_SIZE,
  sort: `${SORT_BY.OFFER_RANK},${SORT_DIRECTION.ASC}`,
};
const getBrandChunkKey = function (brandChunk) {
  return brandChunk.map((brand) => brand?.brand_uid).join('-');
};

export function BrandsList() {
  const fetchUrl = useFetchUrl();
  const { brandFilter, brandFilterOptions, setBrandFilter } = useBrandFilter();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [more, setMore] = useState(true);
  const [page, setPage] = useState(0);

  const { publisherName } = usePublisherName();

  const brandColumns = useBreakpointValue(
    {
      base: 1,
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4,
    },
    {
      fallback: 'md',
    }
  );

  useEffect(() => {
    const abortController = new AbortController();
    let isLatestRequest = true; // to ensure no stale data gets appended

    const getQueryString = () => {
      const queryString = new URLSearchParams(BRANDS_BASE_QUERY);
      queryString.set('page', page);
      Object.entries(brandFilter).forEach(([key, value]) => {
        if (value) {
          queryString.set(key, value);
        }
      });
      return queryString;
    };

    const loadBrands = async () => {
      setLoading(true);
      const queryString = getQueryString();
      const urlWithQueryString = `${BRANDS_API_URL}?${queryString}`;

      try {
        const response = await fetchUrl(urlWithQueryString, {
          signal: abortController.signal,
        });

        const data = await response.json();
        if (!isLatestRequest) return; // if not the latest request, do nothing

        const { content, number: currentPageNumber, totalPages } = data;

        setBrands((p) => {
          if (page === 0) {
            return content;
          }
          return [...p, ...content];
        });
        setMore(totalPages > currentPageNumber + 1);
      } catch (fetchErr) {
        if (fetchErr.name === 'AbortError') {
          // do nothing
        } else {
          handleError(fetchErr, 'Cannot fetch brands');
        }
      } finally {
        if (isLatestRequest) {
          setLoading(false);
        }
      }
    };

    loadBrands();

    return () => {
      isLatestRequest = false; // mark the request as stale
      abortController.abort();
    };
  }, [brandFilter, page, fetchUrl]);

  const fetchMore = () => {
    setPage((p) => p + 1);
  };

  const handleFilterChange = (filter) => {
    setPage(0);
    setBrandFilter(filter);
  };

  const loadingStateProps = {
    opacity: loading && page === 0 ? 0.5 : 1,
    transition: 'opacity 0.3s',
    pointerEvents: loading && page === 0 ? 'none' : 'auto',
  };

  const loadMoreButton = loading ? (
    <Box height={12} alignSelf='center' display='flex' alignItems='center' marginTop={16}>
      <CircularProgress size={8} isIndeterminate color='brand.primary' />
    </Box>
  ) : (
    <Button variant='baseStyle' onClick={fetchMore} alignSelf='center' marginTop={16}>
      Load more
    </Button>
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>Brands & Offers | {publisherName}</title>
      </Helmet>
      <HStack justifyContent='space-between' width='100%' alignItems='center' mt={10} mb={8}>
        <Heading fontWeight={600} as='h2' fontSize='28px' fontFamily='display'>
          Top Deals Picked For You!
        </Heading>
        <BrandFilter
          brandFilter={brandFilter}
          onChange={handleFilterChange}
          brandFilterOptions={brandFilterOptions}
        />
      </HStack>
      {brandColumns > 1
        ? chunk(brands, brandColumns).map((brandChunk) => (
            <SimpleGrid
              key={getBrandChunkKey(brandChunk)}
              columns={brandColumns}
              w='100%'
              m={0}
              pb={5}
              spacing={5}
              {...loadingStateProps}
            >
              {brandChunk.map((brand) => {
                return <Brand key={brand?.brand_uid} brand={brand} />;
              })}
            </SimpleGrid>
          ))
        : brands.map((brand) => (
            <HStack
              data-role='brand-container'
              width='100%'
              justify='center'
              pb={5}
              key={brand?.brand_uid}
              {...loadingStateProps}
            >
              <Brand brand={brand} />
            </HStack>
          ))}

      {more && loadMoreButton}
      {!loading && (
        <>
          {!brands.length && (
            <Box>
              <Text>No Brands Found</Text>
            </Box>
          )}
        </>
      )}
    </React.Fragment>
  );
}
