import { Box, Link, Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { MapIcon } from '~/components/icons';

export const ParticipatingLocations = ({ offer }) => {
  const storeAddresses = offer.store_addresses;

  const firstLocation = storeAddresses[0];

  const searchQuery = encodeURIComponent(firstLocation.complete_address);
  const googleSearchUrl = `https://www.google.com/search?q=${searchQuery}`;

  return (
    <Link href={googleSearchUrl} isExternal _hover={{ textDecoration: 'none' }}>
      <Stack
        borderRadius={12}
        border='1px solid'
        borderColor='secondary.100'
        maxWidth='400px'
        p={4}
        _hover={{
          boxShadow: 'md',
        }}
      >
        <Text color='secondary.primary' fontSize='sm'>
          Participating Store Location
        </Text>
        <Stack alignItems='center' direction='row' gap={4}>
          <Text color='neutral.primary' fontSize='sm' fontWeight='500'>
            {firstLocation.complete_address}
          </Text>
          <Box>
            <Stack
              alignItems='center'
              justifyContent='center'
              rounded='full'
              bgColor='brand.tertiary'
              w={12}
              h={12}
            >
              <MapIcon />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Link>
  );
};

ParticipatingLocations.propTypes = {
  offer: PropTypes.shape({
    store_addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};
