import { Grid, GridItem, VStack } from '@chakra-ui/react';
import { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageContainer } from '~/components/page-container';
import { useAccountState } from '~/hooks/account/useAccountState';
import useAccountSummary from '~/hooks/useAccountSummary';
import useIsNarrowScreen from '~/hooks/useIsNarrowScreen';
import usePublisherName from '~/hooks/usePublisherName';
import { RewardsContainer } from '~/pages/account/rewards/rewards-container';
import { TransactionsSummaryPanel } from '~/pages/account/transactions-summary-panel';
import { UserAndCard } from '~/pages/account/user-and-card';

export function AccountPage() {
  const { isLoggedIn } = useAccountState();
  const narrow = useIsNarrowScreen();
  const [pcHeight, setPcHeight] = useState(0);
  const resizeObserver = useRef(null);

  const { publisherName } = usePublisherName();

  const {
    withdrawals,
    lastRedemptionStatus,
    transactions,
    startDate,
    endDate,
    searchFieldPhrase,
    showMoreTransactions,
    onShowMoreTransactions,
    onTransactionDateChange,
    onResetTransactionDate,
    onTransactionSearchFieldChange,
    onResetWithDrawalSearch,
  } = useAccountSummary();

  const transactionSummaryProps = {
    withdrawals,
    transactions,
    searchFieldPhrase,
    startDate,
    endDate,
    showMore: showMoreTransactions,
    onShowMore: onShowMoreTransactions,
    onSearchFieldChange: onTransactionSearchFieldChange,
    onDateChange: onTransactionDateChange,
    onResetDate: onResetTransactionDate,
    onReset: onResetWithDrawalSearch,
  };

  const onPageContainer = useCallback((ele) => {
    if (resizeObserver.current) {
      resizeObserver.current.disconnect();
    }
    if (ele) {
      setPcHeight(ele.clientHeight);

      resizeObserver.current = new ResizeObserver(() => {
        if (ele.clientHeight !== pcHeight) {
          setPcHeight(ele.clientHeight);
        }
      });

      resizeObserver.current.observe(ele);
    } else {
      setPcHeight(null);
    }
  }, []); // note -- do not add pcHeight as a dependency!

  const ucHeight = useMemo(() => {
    if (narrow || !pcHeight) {
      return undefined;
    }
    return `${pcHeight - 80}px`;
  }, [pcHeight, narrow]);

  if (isLoggedIn) {
    return (
      <Fragment>
        <Helmet>
          <title>Account | {publisherName}</title>
        </Helmet>
        <PageContainer ref={onPageContainer}>
          {narrow ? (
            <VStack w='100%' alignItems='stretch' data-role='narrow-vstack' p='20px'>
              <UserAndCard />
              <RewardsContainer lastRedemptionStatus={lastRedemptionStatus} />
              <TransactionsSummaryPanel narrow {...transactionSummaryProps} />
            </VStack>
          ) : (
            <Grid
              width='100%'
              minHeight='100%'
              columnGap='40px'
              templateColumns='320px auto'
              rowGap='40px'
              templateRows='310px auto'
            >
              <GridItem rowSpan={2} alignSelf='flex-start' minHeight={ucHeight}>
                <UserAndCard />
              </GridItem>
              <GridItem>
                <RewardsContainer lastRedemptionStatus={lastRedemptionStatus} />
              </GridItem>
              <GridItem pb='40px'>
                <TransactionsSummaryPanel narrow={narrow} {...transactionSummaryProps} />
              </GridItem>
            </Grid>
          )}
        </PageContainer>
      </Fragment>
    );
  }
}
