import { message,TOAST_DURATION, TOAST_STATUS } from '../message';

/**
 * @description A subclasses of Error with additional fields which may be passed on to a toast message.
 */
export class CustomError extends Error {
  /**
   *
   * @param {string} title
   * @param {string} description
   */
  constructor({ title, description }) {
    super();

    this.title = title;
    this.description = description;
  }
}

const DEFAULT_DESCRIPTION = 'Please try again';

/**
 *
 * @param {*} error
 * @returns {boolean}
 * @description Returns true if the argument is an instance of {@link CustomError}.
 */
export const isCustomError = (error) => error instanceof CustomError;

/**
 *
 * @param {CustomError} error
 * @description A handler meant for errors of type {@link CustomError}.
 * Results in a custom toast message being rendered.
 */
export const handleCustomError = (error, titleArg) => {
  let title = error.title || titleArg;
  let description = error.description || error.message || DEFAULT_DESCRIPTION;

  message({
    title,
    description,
    status: TOAST_STATUS.ERROR,
    duration: TOAST_DURATION.NORMAL,
    isClosable: true,
  });
};
