import { Button, Spinner, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslations } from '~/hooks/useTranslations';

const url = new URL(window.location.href);

const HOSTED_PAYMENT_OPTIONS = {
  showBankAccount: false,
};

const HOSTED_PAYMENT_RETURN_OPTIONS = {
  showReceipt: true,
  url: url.origin,
  urlText: 'Instant Cashback',
  cancelUrl: url.origin,
  cancelUrlText: 'Cancel',
};

const HOSTED_PAYMENT_BUTTON_OPTIONS = {
  text: 'Instant Cashback',
};

const VITE_GIFT_CARD_PAYMENT =
  import.meta.env.VITE_GIFT_CARD_PAYMENT ?? 'https://accept.authorize.net/payment/payment';

export default function HostedGiftCardForm({ token }) {
  const { giftCardTranslation } = useTranslations();

  useEffect(() => {
    let t = null;

    function openForm() {
      const submit = document.getElementById('btnContinue');
      if (submit) {
        submit.click();
        return;
      }
      t = setTimeout(openForm, 100);
    }

    openForm();
    return () => clearTimeout(t);
  }, []);

  return (
    <VStack mt={4}>
      <Spinner />
      <form
        method='post'
        action={VITE_GIFT_CARD_PAYMENT}
        id='formAuthorizeNetTestPage'
        name='formAuthorizeNetTestPage'
      >
        <input
          type='hidden'
          name='hostedPaymentPaymentOptions'
          value={JSON.stringify(HOSTED_PAYMENT_OPTIONS)}
        />
        <input
          type='hidden'
          name='hostedPaymentButtonOptions'
          value={JSON.stringify(HOSTED_PAYMENT_BUTTON_OPTIONS)}
        />{' '}
        <input
          type='hidden'
          name='hostedPaymentReturnOptions'
          value={JSON.stringify(HOSTED_PAYMENT_RETURN_OPTIONS)}
        />
        <input type='hidden' name='token' value={token} />
        {giftCardTranslation.authorizeNet}
        <Button
          visibility='hidden'
          aria-hidden
          type='submit'
          id='btnContinue'
          width={0}
          height={0}
          padding={0}
          position='absolute'
        >
          Pay for Gift Card
        </Button>
      </form>
    </VStack>
  );
}

HostedGiftCardForm.propTypes = {
  token: PropTypes.string,
};
