import { useContext } from 'react';
import { AlertStateContext } from '~/lib/state/contexts/AlertContext';

export const useAlertState = () => {
  const alertStateValues = useContext(AlertStateContext);
  if (!alertStateValues) {
    throw new Error('useAlertState must be used within AlertProvider');
  }
  return alertStateValues;
};
