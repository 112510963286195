export const landingTranslation = {
  cashback_text: 'Automatically earn cashback on your purchases',
  getting_started_title: 'Getting started is easy',
  link_cards_text: 'Connect your credit/debit cards easily and securely',
  plug_cashback_text: {
    title: 'Spend a little. Live a lot.',
    description:
      "We're constantly adding more and more brands where you can earn cashback, so keep checking back weekly!",
  },
  plug_link_cards_text: {
    title: 'Connect your go-to cards',
    description: 'Link your favorite credit and debit cards, even that trendy new one.',
  },
  brand_container: {
    cashback_button: 'Earn up to 20% cash back',
    heading_text: 'Free cash from top brands, every time you shop',
    subheading_text: 'The most simple money-saving life hack that you should know about',
  },
  sign_up_rewards_text: 'Sign Up For Rewards',
  shopping_text: 'Make sure you use one of your linked cards whenever you shop',
  signup_container: {
    title: 'Ready to get started?',
    description: 'Sign up and start earning.',
  },
  questions_and_answers_us: [
    {
      question: 'How do I withdraw my cash?',
      answer:
        'When you have reached the minimum withdrawal threshold of $40, follow the link within your account to make a withdrawal of your cashback balance.',
    },
    {
      question: 'Are my cards secure?',
      answer:
        'In order to protect your data, we use a fully PCI-DSS compliant third-party to connect your card. Plus, we never ask you for the security number on your card.',
    },
    {
      question: 'Will I start earning cashback right away?',
      answer:
        "Yes. You will start to earning cashback on any new transactions that you make at participating retailers once you've added a card to your account.",
    },
  ],
  questions_and_answers_uk: [
    {
      question: 'How do I withdraw my cash?',
      answer:
        "When you've reached the minimum withdrawal threshold of £40, follow the link within your account to make a withdrawal of your cashback balance. We'll send it via BACS straight to your bank account.",
    },
    {
      question: 'Are my cards secure?',
      answer:
        'We never ask you for any card data. Instead, you login to your bank as normal and you authorise us to look for transactions where we can apply cashback. Security is our top priority - we are ISO 27001 certified.',
    },
    {
      question: 'Will I start earning cashback right away?',
      answer:
        "Yes. When you've added a card to your account, you will start to receive cashback on any new transactions that you make at participating retailers.",
    },
  ],
  questions_title: 'Still have questions?',
};
