import { VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { PAGE_CONTAINER_MAX_WIDTH, PAGE_CONTAINER_X_MARGIN } from '~/theme/constants';

export const PageContainer = forwardRef(({ children, ...props }, ref) => {
  return (
    <VStack
      ref={ref}
      spacing={0}
      my={10}
      mx='auto'
      flexGrow={1}
      width={`calc(100% - ${PAGE_CONTAINER_X_MARGIN} - ${PAGE_CONTAINER_X_MARGIN})`}
      maxWidth={PAGE_CONTAINER_MAX_WIDTH}
      {...props}
    >
      {children}
    </VStack>
  );
});

PageContainer.displayName = 'PageContainer';

PageContainer.propTypes = {
  children: PropTypes.node,
};
