import { EXCHANGE_TOKEN_URL, USER_URL } from '~/lib/constants';
import { BadStatusError } from '~/lib/errors';
import { responseStatusIsGood } from '~/lib/helpers';

export const callSignIn = async (email, password) => {
  try {
    // keep API call here and use native fetch to avoid creating a circular dependency with useFetchUrl
    const response = await fetch(
      `${USER_URL}login`,
      {
        body: JSON.stringify({ email, password }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      { omitAccessToken: true }
    );

    if (!responseStatusIsGood(response)) {
      throw new BadStatusError(response);
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const exchangeToken = async (oneTimeToken) => {
  try {
    // keep API call here and use native fetch to avoid creating a circular dependency with useFetchUrl
    const response = await fetch(EXCHANGE_TOKEN_URL, {
      method: 'POST',
      body: JSON.stringify({ token: oneTimeToken }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!responseStatusIsGood(response)) {
      throw new BadStatusError(response);
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const refreshAuth = async ({ accessTokenRef, refreshTokenRef }) => {
  // keep API call here and use native fetch to avoid creating a circular dependency with useFetchUrl
  const response = await fetch(`${USER_URL}refresh-token`, {
    body: JSON.stringify({ refresh_token: refreshTokenRef.current }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessTokenRef.current}`,
    },
  });

  if (!responseStatusIsGood(response)) {
    throw new Error();
  }

  return await response.json();
};

/**
 * @param {{ userEmail: string, accessToken: string | null, accessTokenExpiration: number | null, changePasswordAccessToken: string | null, refreshToken: string | null }} authData authentication-related data
 * @returns {undefined}
 * @description Accepts auth data and sets it in localStorage synchronously
 */
export const updateSerializedData = ({
  userEmail,
  accessToken,
  accessTokenExpiration,
  changePasswordAccessToken,
  refreshToken,
}) => {
  localStorage.setItem('userEmail', userEmail ?? '');
  localStorage.setItem('accessToken', accessToken ?? '');
  localStorage.setItem('accessTokenExpiration', accessTokenExpiration ?? '');
  localStorage.setItem('changePasswordAccessToken', changePasswordAccessToken ?? '');
  localStorage.setItem('refreshToken', refreshToken ?? '');
};

/**
 * @returns {undefined}
 * @description Removes personalized auth data from localStorage
 */
export const clearSerializedAuthData = () => {
  if (localStorage) {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenExpiration');
    localStorage.removeItem('changePasswordAccessToken');
    localStorage.removeItem('refreshToken');
  }
};
