import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { ChangePasswordFormModal } from './change-password-form-modal';
import { ChangePasswordSuccessModal } from './change-password-success-modal';
import { SettingsModalRoot } from './settings-modal-root';

export const settingsModalView = {
  root: 'root',
  changePassword: {
    form: 'changePassword.form',
    success: 'changePassword.success',
  },
  delete: {
    confirm: 'delete.confirm',
    password: 'delete.password',
    success: 'delete.success',
  },
};
const DEFAULT_VIEW = settingsModalView.root;

export const SettingsModalContainer = ({ isOpen, onClose, initialView }) => {
  const [view, setView] = useState(initialView ?? DEFAULT_VIEW);

  let content;
  switch (view) {
    case settingsModalView.root: {
      content = <SettingsModalRoot setView={setView} />;
      break;
    }
    case settingsModalView.changePassword.form: {
      content = <ChangePasswordFormModal setView={setView} />;
      break;
    }
    case settingsModalView.changePassword.success: {
      content = <ChangePasswordSuccessModal />;
      break;
    }
    case settingsModalView.delete.confirm:
    case settingsModalView.delete.password:
    case settingsModalView.delete.success:
    default: {
      content = null;
      break;
    }
  }

  const handleClose = useCallback(() => {
    setView(DEFAULT_VIEW);
    onClose();
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered blockScrollOnMount>
      <ModalOverlay />
      <ModalContent>{content}</ModalContent>
    </Modal>
  );
};

SettingsModalContainer.propTypes = {
  initialView: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
