const CoinsIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}
    viewBox='0 0 48 48'
    {...props}
  >
    <ellipse
      cx={150}
      cy={40}
      rx={150}
      ry={40}
      style={{
        fill: 'white',
      }}
      transform='scale(.09375 .10102)'
    />
    <path
      d='M300-12.071v51.859h-.002L300 40c0 22.077-67.213 40-150 40S0 62.077 0 40l.002-.212H0v-51.859C.056 9.993 67.247 27.901 150 27.901S299.944 9.993 300-12.071Z'
      style={{
        fill: 'white',
      }}
      transform='matrix(.09375 0 0 .10102 0 9.75)'
    />
    <path
      d='M300-12.071v51.859h-.002L300 40c0 22.077-67.213 40-150 40S0 62.077 0 40l.002-.212H0v-51.859C.056 9.993 67.247 27.901 150 27.901S299.944 9.993 300-12.071Z'
      style={{
        fill: 'white',
      }}
      transform='matrix(.09375 0 0 .10102 0 18.333)'
    />
    <path
      d='M300-12.071v51.859h-.002L300 40c0 22.077-67.213 40-150 40S0 62.077 0 40l.002-.212H0v-51.859C.056 9.993 67.247 27.901 150 27.901S299.944 9.993 300-12.071Z'
      style={{
        fill: 'white',
      }}
      transform='matrix(.0877 0 0 .0945 21.69 33.253)'
    />
    <path
      d='M300-12.071v51.859h-.002L300 40c0 22.077-67.213 40-150 40S0 62.077 0 40l.002-.212H0v-51.859C.056 9.993 67.247 27.901 150 27.901S299.944 9.993 300-12.071Z'
      style={{
        fill: 'white',
      }}
      transform='matrix(.0877 0 0 .0945 21.69 40.44)'
    />
    <path
      d='M200 77.72C184.358 79.197 167.528 80 150 80 67.213 80 0 62.077 0 40l.002-.212H0v-51.859C.056 9.993 67.247 27.901 150 27.901c17.528 0 34.358-.804 50-2.28V77.72Zm98.9-84.956c.722-1.585 1.096-3.199 1.1-4.835v4.835h-1.1Z'
      style={{
        fill: 'white',
      }}
      transform='matrix(.09375 0 0 .10102 0 26.915)'
    />
    <path
      d='M200 77.72C184.358 79.197 167.528 80 150 80 67.213 80 0 62.077 0 40l.002-.212H0v-51.859C.056 9.993 67.247 27.901 150 27.901c17.528 0 34.358-.804 50-2.28V77.72Z'
      style={{
        fill: 'white',
      }}
      transform='matrix(.09375 0 0 .10102 0 35.498)'
    />
    <ellipse
      cx={150}
      cy={40}
      rx={150}
      ry={40}
      style={{
        fill: 'white',
      }}
      transform='matrix(.0877 0 0 .10102 21.69 25.791)'
    />
  </svg>
);
export default CoinsIcon;
